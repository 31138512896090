import { FC } from 'react';
import cn from 'classnames';
import { RefreshIcon } from '../../components/icons/refresh';
import { WentWrongImage } from '../../components/icons/went-wrong';
import { IWentWrongProps } from './went-wrong.models';
import { useWentWrongStyles } from './went-wrong.styles';

export const WentWrong: FC<IWentWrongProps> = ({
  className,
  content,
  image = <WentWrongImage />,
  title = 'Что-то пошло не так',
  subtitle = 'но мы скоро всё починим',
  btnText = 'Перезагрузить страницу',
  btnContent,
  button,
  icon,
  onButtonClickHandler,
  ...other
}) => {
  const classes = useWentWrongStyles();

  const onClickHandler = (): void => {
    if (onButtonClickHandler) {
      onButtonClickHandler();

      return;
    }

    window.location.reload();
  };

  return (
    <div className={cn(className, classes.root)} {...other}>
      <div className={classes.contentWrapper}>
        {content ?? (
          <div className={classes.content}>
            {image}
            <h3 className={classes.title}>
              {title}
            </h3>
            <p className={classes.subtitle}>
              {subtitle}
            </p>
            {button ?? (
              <div className={classes.button} onClick={onClickHandler}>
                {btnContent ?? (<>
                  {icon ?? (
                    <RefreshIcon />
                  )}
                  {btnText}
                </>)}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
