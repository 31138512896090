import { FC, SVGProps } from 'react';

export const DuplicateIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 13H5.5C5.10218 13 4.72064 12.842 4.43934 12.5607C4.15804 12.2794 4 11.8978 4 11.5V5.5C4 5.10218 4.15804 4.72064 4.43934 4.43934C4.72064 4.15804 5.10218 4 5.5 4H11.5C11.8978 4 12.2794 4.15804 12.5607 4.43934C12.842 4.72064 13 5.10218 13 5.5V7M8.5 16H14.5C14.8978 16 15.2794 15.842 15.5607 15.5607C15.842 15.2794 16 14.8978 16 14.5V8.5C16 8.10218 15.842 7.72064 15.5607 7.43934C15.2794 7.15804 14.8978 7 14.5 7H8.5C8.10218 7 7.72064 7.15804 7.43934 7.43934C7.15804 7.72064 7 8.10218 7 8.5V14.5C7 14.8978 7.15804 15.2794 7.43934 15.5607C7.72064 15.842 8.10218 16 8.5 16Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
