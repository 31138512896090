export const uniqByKeepFirst = <TData = unknown>(
  array: TData[],
  keyFunc: (item: TData) => string | number,
): TData[] => {
  const seen = new Set();

  return array.filter((item) => {
    const key = keyFunc(item);

    return seen.has(key) ? false : seen.add(key);
  });
};
