import { Outlet, RouteObject } from 'react-router-dom';
import { BrowserRoute } from '../../routes/browser.routes';
import { Main } from '../components/main';
import { Access } from '../content/access';
import { Auth } from '../content/auth/auth.view';
import { Dictionary } from '../content/dictionary';
import { Intro } from '../content/intro';
import { ModelForm } from '../content/model-form';
import { ModelReadonlyWrapper } from '../content/model-readonly';
import { NotFound } from '../content/not-found';

export const appRoutesDefinition: RouteObject[] = [{
  path: '/',
  element: (
    <Auth>
      <Access>
        <Outlet />
      </Access>
    </Auth>
  ),
  children: [
    {
      path: '*',
      element: <NotFound />,
    },
    {
      path: BrowserRoute.index,
      element: (
        <Main>
          <Intro />
        </Main>
      ),
    },
    {
      path: BrowserRoute.readModel,
      element: (
        <Main>
          <Dictionary>
            <ModelReadonlyWrapper />
          </Dictionary>
        </Main>
      ),
    },
    {
      path: BrowserRoute.duplicatedModel,
      element: (
        <Main>
          <Dictionary>
            <ModelForm />
          </Dictionary>
        </Main>
      ),
    },
    {
      path: BrowserRoute.updateModel,
      element: (
        <Main>
          <Dictionary>
            <ModelForm />
          </Dictionary>
        </Main>
      ),
    },
    {
      path: BrowserRoute.createModel,
      element: (
        <Main>
          <Dictionary>
            <ModelForm />
          </Dictionary>
        </Main>
      ),
    },
  ],
}];
