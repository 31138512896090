import { createUseStyles } from 'react-jss';

export const useHintTextStyles = createUseStyles(
  (theme) => ({
    root: {
      fontSize: 13,
      minHeight: 20,
    },
    colorDefault: {
      color: theme.palette.lines.main,
    },
    colorError: {
      color: theme.palette.error.main,
    },
    colorWarning: {
      color: theme.palette.warning.main,
    },
    colorSuccess: {
      color: theme.palette.success.main,
    },
  }),
);
