import { jss } from 'react-jss';

const PREFIX = 'Notification';

export const renderStyles = (): { [key: string]: string } => {
  const { classes } = jss.createStyleSheet({
    toast: {
      boxShadow: '0 0 #000000, 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1)',
      backgroundColor: '#ffffff',
      borderTop: '3px solid transparent',
      borderRadius: '0.25rem',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      gap: '0.75rem',
      minWidth: '350px',
      width: '350px',
      marginBottom: '0.5rem',
      marginTop: '0.5rem',
      position: 'relative',
    },

    toast_default: {
      borderTopColor: '#0071CE',
    },

    toast_warning: {
      borderTopColor: '#F7B055',
    },

    toast_error: {
      borderTopColor: '#EB5757',
    },

    toast_success: {
      borderTopColor: '#73D071',
    },

    toast__wrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flex: 1,
    },

    toast__text: {
      margin: 0,
      whiteSpace: 'pre-line',
      fontWeight: '700',
      color: '#3B4168',
      lineHeight: '24px',
      fontSize: '14px',
    },

    toast__dismiss_icon: {
      color: '#3B4168',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '1rem',
      height: '1rem',
    },

    '@keyframes loadingProgressAnimation': {
      from: {
        transform: 'translateX(-100%)',
      },
      to: {
        transform: 'translateX(0)',
      },
    },

    toast__progress: {
      position: 'absolute',
      left: '-20px',
      right: '-20px',
      bottom: '-12px',
      height: '2px',
      animationDuration: '5s',
      animation: '$loadingProgressAnimation ease-in',
    },

    toast__progress_default: {
      backgroundColor: '#0071CE',
    },

    toast__progress_warning: {
      backgroundColor: '#F7B055',
    },

    toast__progress_error: {
      backgroundColor: '#EB5757',
    },

    toast__progress_success: {
      backgroundColor: '#73D071',
    },
  }, {
    link: true,
    classNamePrefix: PREFIX,
  }).attach();

  return classes;
};
