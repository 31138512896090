import { Toast, ToastOptions } from 'react-hot-toast';

export enum ToastTypes {
  Default = 'default',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

type NotificationType = 'default' | 'warning' | 'success' | 'error';

export interface INotificationProps {
  text: string;
  opts?: ToastOptions;
  type?: NotificationType;
  loadingBar?: boolean;
}

export type INotificationElementProps = INotificationProps & Toast;
