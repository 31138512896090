import { KeyboardEvent } from 'react';
import { ICourseModelLevel } from '@unione-pro/unione.assmnt.sdk.webapp';

const ERROR_MESSAGES = {
  required: 'Обязательное поле',
  integer: 'Значение должно быть целым числом',
};

export const parseToNumber = (value?: string): number | null => {
  if (!value) {
    return null;
  }

  return Number(value);
};

type MinMaxOptions = {
  min?: number;
  max?: number;
  isDisabled?: boolean;
};

export const requiredCheck = (value: string | number): string | undefined => {
  if (value) {
    return undefined;
  }

  if (typeof value === 'number') {
    return undefined;
  }

  return ERROR_MESSAGES.required;
};

export const checkMinMaxValue = ({ max, min }: MinMaxOptions) => (value: number): string | undefined => {
  const requiredErrorMessage = requiredCheck(value);

  if (requiredErrorMessage) {
    return requiredErrorMessage;
  }

  if (typeof min === 'number' && value < min) {
    return `Значение не может быть меньше ${min}`;
  }

  if (typeof max === 'number' && value > max) {
    return `Значение не может быть больше ${max}`;
  }

  if (!Number.isInteger(value)) {
    return ERROR_MESSAGES.integer;
  }

  return undefined;
};

const checkMinMaxLevelValue = checkMinMaxValue({ min: 2, max: 100 });

// TODO переделать через yup
export const levelRangesValidate = (levels: ICourseModelLevel[]): object => levels.map((level, index) => {
  const { to, from } = level;

  const nextLevel = levels[index + 1];
  const prevLevel = levels[index - 1];

  let fromError = index !== 0 ? checkMinMaxLevelValue(from) : undefined;
  let toError = checkMinMaxLevelValue(to);

  if (typeof from !== 'number') {
    fromError = ERROR_MESSAGES.required;
  }

  if (typeof to !== 'number') {
    toError = ERROR_MESSAGES.required;
  }

  if (!toError && !Number.isInteger(to)) {
    toError = ERROR_MESSAGES.integer;
  }

  if (!fromError && !Number.isInteger(from)) {
    fromError = ERROR_MESSAGES.integer;
  }

  if (!toError && to <= from) {
    toError = 'Значение должно быть больше текущего "От"';
  }

  if (!fromError && from >= to) {
    fromError = 'Значение должно быть меньше текущего "До"';
  }

  if (!fromError && prevLevel && from <= prevLevel.to) {
    fromError = 'Значение должно быть больше предыдущего "До"';
  }

  if (!toError && nextLevel && nextLevel.from <= to) {
    toError = 'Значение должно быть меньше следующего "От"';
  }

  if (!fromError && prevLevel && from - prevLevel.to !== 1) {
    fromError = 'Между диапазонами не должно быть пробела больше одного.';
  }

  if (!toError && nextLevel && nextLevel.from - to !== 1) {
    toError = 'Между диапазонами не должно быть пробела больше одного.';
  }

  if (!fromError && !toError) {
    return undefined;
  }

  return {
    from: fromError,
    to: toError,
  };
});

type ErrorField = { [key: string]: string | number };

export const getInvalidFieldId = (errorField: ErrorField | ErrorField[], fieldId: string): string => {
  const [[key, value]] = Object.entries(errorField);

  const newFieldId = `${fieldId}.${key}`;

  if (!value && Array.isArray(errorField)) {
    const findNextInvalidFieldError = errorField.find((field) => field) || {};

    return getInvalidFieldId(findNextInvalidFieldError, newFieldId);
  }

  if (!value) {
    return fieldId;
  }

  if (typeof value !== 'object') {
    return newFieldId;
  }

  if (Array.isArray(value)) {
    return getInvalidFieldId(value, newFieldId);
  }

  return getInvalidFieldId(value, newFieldId);
};

export const ignoreFloatingPointNumber = (event: KeyboardEvent<HTMLInputElement>): void => {
  if (['.', ','].includes(event.key)) {
    event.preventDefault();
  }
};
