import React, { FC } from 'react';
import { ModelStage } from '@unione-pro/unione.assmnt.sdk.webapp';
import classNames from 'classnames';
import { ITabProps } from './tab.models';
import { useTabStyles } from './tab.styles';

export const Tab: FC<ITabProps> = ({
  className,
  children,
  variant = 'published',
}) => {
  const classes = useTabStyles();

  const rootCN = classNames(
    classes.tab,
    { [classes.published]: variant === ModelStage.published },
    { [classes.draft]: variant === ModelStage.draft },
    { [classes.published]: variant === 'published' },
    { [classes.draft]: variant === 'draft' },
    { [classes.level]: variant === 'level' },
    className,
  );

  return (
    <p className={rootCN}>
      {children}
    </p>
  );
};
