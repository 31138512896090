import { createUseStyles } from 'react-jss';

export const useModelReadonlyWrapperStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    rowGap: '1.5rem',
  },

  page: {
    display: 'flex',
    flex: '1 1',
    columnGap: '1.5rem',
  },

  actions: {
    boxShadow: theme.shadow.actions,
  },

  [`@media (max-width: ${theme.breakpoints.lg}px)`]: {
    page: {
      flexDirection: 'column-reverse',
      rowGap: '1.5rem',

      '& $actions': {
        position: 'static',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        columnGap: 28,
        padding: '1.75rem',
      },
    },
  },
}));

export const useModelReadonlyStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flex: '1 1',
    flexDirection: 'column',
    rowGap: '1.5rem',
  },

  block: {
    borderRadius: '0.25rem',
    background: 'white',
    padding: '32px 40px 40px',
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  mainTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '24px',
    lineHeight: '28px',
    color: theme.palette.gloomyBlue.main,
  },

  type: {
    display: 'flex',
    alignItems: 'center',
  },

  typeDate: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '14px',
    lineHeight: '20px',
    color: theme.palette.gray.contrastText,
    marginRight: '28px',
  },

  line: {
    margin: '2rem 0',
  },

  testParamsBlockColumns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    rowGap: '24px',
    columnGap: '28px',
  },

  block_columns: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    gap: 24,
  },

  blockRow: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },

  blockColSpan: {
    gridColumn: 'span 2 / span 2',
  },

  blockTitle: {
    fontSize: '0.875rem',
    lineHeight: '1.25rem',
    color: theme.palette.secondaryText.main,
  },

  blockSubtitle: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    color: theme.palette.gloomyBlue.main,
  },

  blockWithLines: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '16px',
    marginTop: '24px',
  },

  blockWithLinesContent: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '28px',
    rowGap: '16px',
  },

  blockWithLinesRow: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    width: '400px',
  },

  fullWeight: {
    width: '100%',
  },

  blockRanges: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    rowGap: '24px',
    columnGap: '28px',
    width: '100%',
  },

  blockRangesContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    rowGap: '8px',
  },

  questionsBlock: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    rowGap: '24px',
    columnGap: '28px',
  },

  weightLevelsContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '24px',
  },

  weightLevelsBlock: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 380px))',
    rowGap: '24px',
    columnGap: '28px',
  },

  rangeSettingBlock: {
    marginTop: 24,
    padding: '0!important',
  },

  rangeSettingContent: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 50%))',
    rowGap: '24px',
    columnGap: '28px',
  },

  questionsLine: {
    margin: '16px 0',
  },

  blockParams: {
    padding: '0 !important',
  },

  stages: {
    display: 'flex',
    gap: 12,
  },

  stage: {
    color: theme.background.paper,
    padding: ['4px', '16px'],
    borderRadius: 4,
  },

}));
