import { createUseStyles } from 'react-jss';
import Color from 'color';

const PREFIX = 'Button';

const buttonClasses = {
  colorPrimary: `${PREFIX}-colorPrimary`,
  disabled: `${PREFIX}-disabled`,
};

export const useButtonStyles = createUseStyles(
  (theme) => ({
    root: {
      transitionDuration: theme.transitions.duration.fast,
      border: '2px solid transparent',
      borderRadius: theme.shape.borderRadius,
      margin: 0,
      fontWeight: theme.typography.fontWeightMedium,
      appearance: 'none',
      outline: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      columnGap: '12px',

      [`&.${buttonClasses.disabled}`]: {
        backgroundColor: theme.palette.disabledLight.main,
        color: theme.palette.disableGray.main,
        pointerEvents: 'none',
        cursor: 'default',
      },
    },
    disabled: {},
    sizeNone: {
      padding: 0,
    },
    sizeSmall: {
      padding: '12px 24px',
    },
    sizeMedium: {
      padding: '1.25rem 3rem',
    },
    colorPrimary: {
      backgroundColor: theme.palette.primary.main,
      color: theme.background.paper,

      '&:hover': {
        backgroundColor: Color(theme.palette.primary.main).darken(0.3).toString(),
      },
    },
    colorSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.background.paper,

      '&:hover': {
        backgroundColor: Color(theme.palette.success.main).darken(0.3).toString(),
      },
    },
    colorWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.background.paper,

      '&:hover': {
        backgroundColor: Color(theme.palette.warning.main).darken(0.3).toString(),
      },
    },
    colorError: {
      backgroundColor: theme.palette.error.main,
      color: theme.background.paper,

      '&:hover': {
        backgroundColor: Color(theme.palette.error.main).darken(0.3).toString(),
      },
    },
    colorGray: {
      backgroundColor: theme.palette.lightGray.main,
      color: theme.background.paper,

      '&:hover': {
        backgroundColor: Color(theme.palette.lightGray.main).darken(0.3).toString(),
      },
    },
    colorWhite: {
      backgroundColor: theme.background.paper,
      color: theme.palette.black.main,
    },
    colorPrimaryGradient: {
      backgroundSize: '200%',
      backgroundImage: `linear-gradient(135deg, ${theme.gradient.blue.start}, ${theme.gradient.blue.end})`,
      color: theme.background.paper,

      '&:hover': {
        backgroundPosition: '100%',
      },
    },
    variantOutlined: {
      backgroundColor: 'transparent',
      borderColor: theme.palette.black.main,
      color: theme.palette.black.main,

      '&:hover': {
        backgroundColor: 'inherit',
        textDecoration: 'underline',
      },
    },
    variantStandard: {
      backgroundColor: 'transparent',

      [`&.${buttonClasses.colorPrimary}`]: {
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.primary.main,

        '&:hover': {
          backgroundColor: 'transparent',
          color: Color(theme.palette.primary.main).darken(0.3).toString(),
        },
      },
    },
  }),
);
