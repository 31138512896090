import { createUseStyles } from 'react-jss';

export const useFormCommonStyles = createUseStyles(
  (theme) => ({
    paper: {
      padding: ['32px', '40px', '40px'],
    },
    blockTitle: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 18,
      lineHeight: '24px',
      color: theme.palette.gloomyBlue.main,
      marginBottom: 24,
    },
    fields: {
      display: 'grid',
      alignItems: 'flex-start',
      rowGap: 16,
      columnGap: 24,
      gridTemplateColumns: 'repeat(4, minmax(0, 1fr))',
    },
    field: {
      gridColumn: 'span 2',
    },
    switcher: {
      position: 'relative',
      display: 'inline-block',
      boxSizing: 'border-box',
      minWidth: 32,
      width: 32,
      height: 16,
      lineHeight: '20px',
      padding: 0,
      verticalAlign: 'middle',
      borderRadius: '20px',
      border: '1px solid #909EBB',
      backgroundColor: '#909EBB',
      transition: 'all .3s cubic-bezier(0.35, 0, 0.25, 1)',
      overflow: 'hidden',

      '& .rc-switch-inner-checked': {
        color: '#fff',
        fontSize: '12px',
        position: 'absolute',
        top: 0,
        transition: 'left .3s cubic-bezier(0.35, 0, 0.25, 1)',
        left: '6px',
      },

      '& .rc-switch-inner-unchecked': {
        color: '#fff',
        fontSize: '12px',
        position: 'absolute',
        top: 0,
        transition: 'left .3s cubic-bezier(0.35, 0, 0.25, 1)',
        left: '24px',
      },

      '&:after': {
        position: 'absolute',
        width: 12,
        height: 12,
        left: '2px',
        top: '1px',
        borderRadius: '50% 50%',
        backgroundColor: '#fff',
        content: '" "',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.26)',
        transform: 'scale(1)',
        transition: 'left .3s cubic-bezier(0.35, 0, 0.25, 1)',
        animationTimingFunction: 'cubic-bezier(0.35, 0, 0.25, 1)',
        animationDuration: '.3s',
        animationName: '$rcSwitchOff',
      },

      '&:hover:after': {
        animationName: '$rcSwitchOn',
      },

      '&:focus': {
        boxShadow: '0 0 0 2px tint(#2db7f5, 80%)',
        outline: 'none',
      },

      '&.rc-switch-checked': {
        border: '1px solid #0071CE',
        backgroundColor: '#0071CE',

        '& .rc-switch-inner-checked': {
          left: '6px',
        },

        '& .rc-switch-inner-unchecked': {
          left: 32,
        },

        '&:after': {
          left: 16,
        },
      },

      '&.rc-switch-disabled': {
        cursor: 'no-drop',
        background: '#909EBB',
        borderColor: '#909EBB',

        '& .rc-switch-disabled:after': {
          background: '#9e9e9e',
          animationName: 'none',
          cursor: 'no-drop',
        },

        '& .rc-switch-disabled:hover:after': {
          transform: 'scale(1)',
          animationName: 'none',
        },
      },

      '& .rc-switch-label': {
        display: 'inline-block',
        lineHeight: '20px',
        fontSize: '14px',
        paddingLeft: '10px',
        verticalAlign: 'middle',
        whiteSpace: 'normal',
        pointerEvents: 'none',
        userSelect: 'text',
      },
    },
    between: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
    },
    secondary: {
      color: theme.palette.secondaryText.main,
    },
    marginBottom8: {
      marginBottom: 8,
    },
  }),
  { name: 'FormCommon' },
);

export const useModelFormStyles = createUseStyles(
  (theme) => ({
    title: {
      fontWeight: theme.typography.fontWeightBolder,
      fontSize: '28px',
      lineHeight: '36px',
      color: theme.palette.gloomyBlue.main,
    },
    wrapper: {
      display: 'flex',
      columnGap: 24,
      marginTop: 24,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
      width: '100%',
    },
    flex: {
      display: 'flex',
      columnGap: 24,
      width: '100%',
    },
    btn: {
      marginLeft: 'auto',
      color: theme.palette.primary,
      fontWeight: 500,
      display: 'flex',
      alignItems: 'center',
      gap: 5,

      '&:hover': {
        color: theme.palette.primaryDarken,
      },
    },
    delete: {
      marginTop: 17,
      color: theme.palette.secondaryText,
      cursor: 'pointer',
    },
    loader: {
      position: 'fixed',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      zIndex: 100,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backdropFilter: 'blur(1.1px)',
    },
  }),
  { name: 'ModelForm' },
);
