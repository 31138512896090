import { makeAutoObservable, runInAction } from 'mobx';
import { IRootStore } from '../models/stores/root.store';
import { IUserStore } from '../models/stores/user.store';
import { getErrorMessage } from '../shared/error-message';

export class UserStore implements IUserStore {

  public readonly rootStore: IRootStore;

  public token?: string = undefined;
  public loading: boolean = true;
  public error?: string = undefined;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public async getToken(): Promise<void> {
    try {
      runInAction(() => {
        this.token = undefined;
        this.loading = true;
        this.error = undefined;
      });

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.token = localToken;
          this.loading = false;
        });

        return;
      }
      if (window.unione) {
        const unioneToken = await window.unione.getUnioneToken();

        if (typeof unioneToken === 'string' && unioneToken !== 'null' && unioneToken.length > 0) {
          runInAction(() => {
            this.token = unioneToken;
            this.loading = false;
          });

          return;
        }

        runInAction(() => {
          this.loading = false;
        });
      }
    }
    catch (error) {
      console.error(error);
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

}
