import { FC, ReactElement } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { ITestLevel } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { WEIGHT_LEVELS_FIELD_NAME } from '../../../../../constants/model.constants';
import { States } from '../../../../../models/entities';
import { useAppStore } from '../../../../../stores/context.store';
import { Input } from '../../../../components/input';
import { checkMinMaxValue, parseToNumber } from '../../utils';
import { useFieldsStyles } from '../use-styles';

export const LevelWeights: FC = () => {
  const fieldClasses = useFieldsStyles();
  const { dictionary } = useAppStore();
  const { fields } = useFieldArray(WEIGHT_LEVELS_FIELD_NAME);
  const values: ITestLevel[] = fields.value;

  const getLabel = (index: number): string => {
    const level = values[index].level;
    const label = dictionary.levelLabels[level];

    return label ? `Вес, ${label}` : '';
  };

  return (
    <div className={fieldClasses.column}>
      <Chip label="Весовые коэффициенты. Уровень задания" color="primary" />
      <div className={fieldClasses.fields}>
        {fields.map((fieldName, index) => (
          <Field
            key={fieldName}
            name={`${fieldName}.weight`}
            validate={checkMinMaxValue({ min: 1, max: 10 })}
            render={({ input, meta: { error, touched } }): ReactElement => (
              <Input
                id={`${WEIGHT_LEVELS_FIELD_NAME}.${index}.weight`}
                type="number"
                value={input.value}
                onChange={(event): void => input.onChange(parseToNumber(event.target.value))}
                label={getLabel(index)}
                hintText={touched && error}
                state={(touched && error) ? States.Error : States.Default}
              />
            )}
          />
        ))}
      </div>
    </div>
  );
};
