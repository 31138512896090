import { FC, SVGProps } from 'react';

export const SaveIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 5C3.75 4.30964 4.30964 3.75 5 3.75H13.0605C13.392 3.75 13.7099 3.8817 13.9443 4.11612L14.4709 3.58953L13.9443 4.11612L15.303 5.47477L15.8839 6.05566C16.1183 6.29008 16.25 6.60802 16.25 6.93954V15C16.25 15.6904 15.6904 16.25 15 16.25H5C4.30964 16.25 3.75 15.6904 3.75 15V5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
    <path
      d="M6 16V13C6 12.4478 6.44772 12 7 12H13C13.5523 12 14 12.4478 14 13V16"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M8.00098 3.00024V5.00024C8.00098 5.55253 8.44869 6.00024 9.00098 6.00024H11.001C11.5533 6.00024 12.001 5.55253 12.001 5.00024V3.00024"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
