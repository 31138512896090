import { ModelDetail } from '../../../models/stores/model-detail.store';

export interface IModelReadonlyProps {
  model: ModelDetail;
}

export enum RemoveModalType {
  confirm = 'confirm',
  info = 'info',
}

export interface IRemoveModalProps {
  type: RemoveModalType;
  isOpen: boolean;
  onOk: () => void;
  onCancel: () => void;
}
