import reactHotToast, { ToastOptions } from 'react-hot-toast';
import cn from 'classnames';
import { CloseIcon } from '../icons/close';
import { INotificationProps, ToastTypes } from './notification.models';
import { renderStyles } from './notification.styles';

export const toast = ({
  text,
  opts,
  type = ToastTypes.Default,
  loadingBar,
}: INotificationProps): void => {
  const classes = renderStyles();

  const toastCN = cn(
    classes.toast,
    opts?.className,
    {
      [classes[`toast_${type}`]]: !!type,
    },
  );

  const toastLoadingProgressCN = cn(
    classes.toast__progress,
    {
      [classes[`toast__progress_${type}`]]: !!type,
    },
  );

  const options: ToastOptions = {
    duration: 5000,
    position: 'top-right',
    ...opts,
    className: toastCN,
  };

  reactHotToast(
    ({
      duration = 0,
      id,
    }) => {
      const loadingBarDisplay = loadingBar && duration;

      return (
        <div className={classes.toast__wrapper}>
          <p className={classes.toast__text}>
            {text}
          </p>
          {loadingBarDisplay &&
            <div
              className={toastLoadingProgressCN}
              style={{
                animationDuration: `${duration}ms`,
              }}
            />
          }
          <CloseIcon
            className={classes.toast__dismiss_icon}
            onClick={(): void => reactHotToast.dismiss(id)}
          />
        </div>
      );
    },
    options,
  );
};
