import { createUseStyles } from 'react-jss';
import Color from 'color';
import { MIN_CONTENT_WRAPPER_WIDTH, MIN_SIDEBAR_WIDTH } from '../../components/main/main.styles';

type SidebarRuleNames =
  | 'root'
  | 'container'
  | 'content'
  | 'blur'
  | 'switcher_wrapper'
  | 'switcher'
  | 'search'
  | 'sidebarEmpty'
  | 'resizeBlock';

interface IUseSidebarStyleProps {
  sidebarWidth: number;
  menuActive: boolean;
}

export const useSidebarStyles = createUseStyles<SidebarRuleNames, IUseSidebarStyleProps>(
  (theme) => ({
    root: {
      width: '100%',
      minWidth: MIN_SIDEBAR_WIDTH,
      maxWidth: (props): number => props.sidebarWidth,
      background: 'white',
      maxHeight: 'calc(100vh - 64px)',
      position: 'sticky',
      top: 64,
      zIndex: 1,
    },
    container: {
      padding: {
        top: 16,
        bottom: 40,
      },
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      position: 'sticky',
      top: 0,
      transition: theme.transitions.duration.regular,
      zIndex: 8,
      overflow: 'hidden',
      background: 'white',
    },
    content: {
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    blur: {
      display: 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100vw',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      zIndex: 7,
    },
    switcher_wrapper: {
      display: 'none',
      height: 'calc(100vh - 64px)',
      width: 30,
      position: 'fixed',
      top: 64,
      left: (props): number => (props.menuActive ? 310 : 0),
      zIndex: 9,
      transition: theme.transitions.duration.regular,
    },
    switcher: {
      width: 40,
      height: 40,
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 'calc(50% - 20px)',
      right: 0,
      backgroundColor: theme.background.paper,
      padding: 10,
      boxShadow: theme.shadow.box,
      transform: (props): string => `rotateZ(${!props.menuActive ? '-90' : '90'}deg)`,
      transition: '0.1s',

      '&:hover': {
        backgroundColor: Color(theme.palette.gray.contrastText).lighten(0.4).toString(),
      },
    },
    search: {
      padding: {
        top: 0,
        left: 24,
        right: 24,
        bottom: 17,
      },
    },
    sidebarEmpty: {
      padding: 12,
      textAlign: 'center',
      fontWeight: 500,
      color: theme.palette.secondaryText.main,
    },
    resizeBlock: {
      position: 'absolute',
      top: 0,
      left: '100%',
      zIndex: 10,
      height: '100%',
      background: theme.palette.lines.main,
      paddingRight: 1,
      userSelect: 'none',
      cursor: 'col-resize',
      '&:hover, &:active': {
        paddingRight: 5,
      },
    },
    [`@media (max-width: ${MIN_SIDEBAR_WIDTH + MIN_CONTENT_WRAPPER_WIDTH}px)`]: {
      root: {
        maxWidth: (): number => 0,
        minWidth: 0,
      },
      resizeBlock: {
        display: 'none',
      },
      switcher_wrapper: {
        display: 'block',
      },
      container: {
        top: 0,
        boxShadow: theme.shadow.box,
        width: '100%',
        maxWidth: 'calc(100vw - 200px)',
        minWidth: 320,
        paddingRight: 10,
        transform: (props: IUseSidebarStyleProps): string => (`translateX(${props.menuActive ? 0 : 'calc(-100% + 10px)'})`),
      },
      blur: {
        display: (props: IUseSidebarStyleProps): string => (props.menuActive ? 'block' : 'none'),
      },
    },
  }),
  { name: 'Sidebar' },
);

type SidebarMenuRuleNames =
  | 'block'
  | 'tab'
  | 'title'
  | 'title_icons'
  | 'chevron'
  | 'option'
  | 'option_active'
  | 'icon'
  | 'loading_wrapper'
  | 'scroll_box';

interface IUseSidebarMenuStyleProps {
  open: boolean;
}

export const useSidebarMenuStyles = createUseStyles<SidebarMenuRuleNames, IUseSidebarMenuStyleProps>(
  (theme) => ({
    block: {
      maxHeight: 'calc(100% - 100px)',
      height: 'fit-content',
    },
    tab: {
      background: theme.background.paper,
      display: 'flex',
      cursor: 'pointer',
      alignItems: 'center',
      gap: 14,
      padding: {
        top: 8,
        left: 24,
        right: 14,
        bottom: 8,
      },
      fontWeight: theme.typography.fontWeightMedium,
      transition: theme.transitions.duration.fast,
    },
    title: {
      width: '100%',
      display: 'flex',
      gap: 14,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.gloomyBlue.main,
    },
    title_icons: {
      display: 'flex',
      gap: 14,
      justifyContent: 'space-between',
      alignItems: 'center',
      color: theme.palette.gray.contrastText,
    },
    chevron: {
      color: theme.palette.gray.contrastText,
      rotate: (props): string => (`${props.open ? 180 : 0}deg`),
      transition: '0.1s',
      '&:hover': {
        color: theme.palette.black.main,
      },
    },
    option: {
      cursor: 'pointer',
      padding: {
        top: 8,
        left: 60,
        right: 24,
        bottom: 8,
      },
      transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
      transition: theme.transitions.duration.fast,
      color: theme.palette.secondaryText.main,
      display: 'inline-block',
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      wordWrap: 'break-word',

      '&:hover': {
        textOverflow: 'initial',
        whiteSpace: 'normal',
        background: theme.palette.hover.main,
        color: theme.palette.primary.main,
      },
    },
    option_active: {
      background: theme.palette.hover.main,
      color: theme.palette.primary.main,
    },
    icon: {
      display: 'flex',
      height: 18,
      width: 18,
      alignItems: 'center',
      justifyContent: 'center',
    },
    loading_wrapper: {
      display: 'flex',
      justifyContent: 'center',
      height: 48,
    },
    scroll_box: {
      background:
        'linear-gradient(white 30%, rgba(255, 255, 255, 0)), linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%, radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0)) 0 100%',
      backgroundRepeat: 'no-repeat',
      backgroundColor: theme.background.paper,
      backgroundSize: '100% 40px, 100% 40px, 100% 5px, 100% 5px',
      backgroundAttachment: 'local, local, scroll, scroll',
      overflowY: 'auto',
      transition: theme.transitions.duration.fast,
      maxHeight: (props): string => (props.open ? 'calc(100% - 40px)' : '0'),

      '&::-webkit-scrollbar': {
        width: '3px',
        height: '3px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '10px',
        backgroundColor: '#f5f5f7',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#909ebb',
        borderRadius: '10px',
      },
    },
    '@media (max-width: 1024px)': {
      root: {
        width: 0,
        minWidth: 0,
        maxWidth: 0,
      },
      option: {
        whiteSpace: 'nowrap',
      },
    },
  }),
  { name: 'Sidebar_menu' },
);
