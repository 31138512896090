import {
  ModelStage,
  sdkGetModelsList,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import {
  makeAutoObservable,
  onBecomeObserved,
  reaction,
  runInAction,
} from 'mobx';
import {
  IGetModelSidebarParams,
  IModelSidebarStore,
  IModelSidebarStoreList,
} from '../models/stores/model-sidebar.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';
import { uniqByKeepFirst } from '../shared/uniqs';

const DEFAULT_STATE = {
  data: null,
  list: [],
  pages: [],
  page: undefined,
  total: 0,
  loading: true,
  error: undefined,
};

const DEFAULT_PARAMS = {
  page: 1,
  limit: 20,
};

export class ModelSidebarStore implements IModelSidebarStore {

  public readonly rootStore: IRootStore;

  public draft: IModelSidebarStoreList = DEFAULT_STATE;
  public published: IModelSidebarStoreList = DEFAULT_STATE;
  public draftParams: IGetModelSidebarParams = DEFAULT_PARAMS;
  public publishedParams: IGetModelSidebarParams = DEFAULT_PARAMS;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
    onBecomeObserved(this, 'draft', this.getDraft);
    reaction(() => this.draftParams, this.getDraft);
    onBecomeObserved(this, 'published', this.getPublished);
    reaction(() => this.publishedParams, this.getPublished);
  }

  public changeParams = (
    params: IGetModelSidebarParams,
    type: ModelStage,
  ): void => {
    if (type === ModelStage.draft) {
      this.draftParams = params;
    }
    if (type === ModelStage.published) {
      this.publishedParams = params;
    }
  };

  public resetDraft(): void {
    this.draft = DEFAULT_STATE;
  }

  public resetPublished(): void {
    this.published = DEFAULT_STATE;
  }

  public resetDraftAndRequest(): void {
    this.resetDraft();
    this.draftParams = DEFAULT_PARAMS;
  }

  public resetPublishedAndRequest(): void {
    this.resetPublished();
    this.publishedParams = DEFAULT_PARAMS;
  }

  public getDraft = async(): Promise<void> => {
    const params = this.draftParams;
    if (this.draft.pages.includes(params.page)) {
      return;
    }

    try {
      runInAction(() => {
        this.draft = {
          page: undefined,
          total: 0,
          data: null,
          list: this.draft.list,
          pages: this.draft.pages,
          loading: true,
          error: undefined,
        };
      });

      const response = await sdkGetModelsList({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
        data: {
          ...params,
          stage: ModelStage.draft,
        },
      });

      runInAction(() => {
        this.draft = {
          page: params.page,
          data: response.items,
          total: response.total,
          list: [...this.draft.list, ...response.items],
          pages: uniqByKeepFirst(
            [...this.draft.pages, params.page],
            (item) => item,
          ),
          loading: false,
        };
      });
    }
    catch (error) {
      runInAction(() => {
        this.draft = {
          ...this.draft,
          loading: false,
          error: getErrorMessage(error),
        };
      });
    }
  };

  public getPublished = async(): Promise<void> => {
    const params = this.publishedParams;
    if (this.published.pages.includes(params.page)) {
      return;
    }

    try {
      runInAction(() => {
        this.published = {
          page: undefined,
          total: 0,
          data: null,
          list: this.published.list,
          pages: this.published.pages,
          loading: true,
          error: undefined,
        };
      });

      const response = await sdkGetModelsList({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
        data: {
          ...params,
          stage: ModelStage.published,
        },
      });

      runInAction(() => {
        this.published = {
          page: params.page,
          data: response.items,
          total: response.total,
          list: [...this.published.list, ...response.items],
          pages: uniqByKeepFirst(
            [...this.published.pages, params.page],
            (item) => item,
          ),
          loading: false,
        };
      });
    }
    catch (error) {
      runInAction(() => {
        this.published = {
          ...this.published,
          loading: false,
          error: getErrorMessage(error),
        };
      });
    }
  };

}
