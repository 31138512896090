import { FC, SVGProps } from 'react';

export const WentWrongImage: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="444"
    height="320"
    viewBox="0 0 444 320"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M205.269 20.8387C216.419 2.1042 243.581 2.10425 254.731 20.8387L394.918 256.382C406.315 275.532 392.495 299.788 370.187 299.788H89.8128C67.5047 299.788 53.6848 275.532 65.0822 256.382L205.269 20.8387Z"
      fill="#EB5757"
    />
    <path
      d="M177.377 26.285C185.793 6.98004 211.816 3.69844 224.767 20.3101L393.448 236.679C406.684 253.657 396.381 278.578 375.006 281.273L96.708 316.362C75.3328 319.057 59.1587 297.475 67.7601 277.743L177.377 26.285Z"
      stroke="#3B4168"
      strokeWidth="2"
    />
    <path
      d="M212.493 95.2622C212.224 88.454 217.671 82.7878 224.484 82.7878L235.516 82.7878C242.329 82.7878 247.776 88.454 247.507 95.2622L243.313 201.262C243.058 207.7 237.765 212.788 231.322 212.788H228.678C222.235 212.788 216.942 207.7 216.687 201.262L212.493 95.2622Z"
      fill="#F5F6F8"
    />
    <ellipse cx="230" cy="240.288" rx="13" ry="13.5" fill="#F5F6F8" />
    <path
      d="M194.096 101.003C193.036 94.848 197.308 89.0466 203.5 88.2312L214.437 86.791C220.63 85.9756 226.258 90.4733 226.828 96.6929L236.509 202.333C237.048 208.214 232.846 213.472 226.991 214.243L224.369 214.588C218.513 215.359 213.094 211.369 212.092 205.548L194.096 101.003Z"
      stroke="#3B4168"
      strokeWidth="2"
    />
    <path
      d="M241.298 241.105C242.204 247.985 237.568 254.204 231.032 255.065C224.497 255.925 218.409 251.118 217.503 244.238C216.597 237.358 221.233 231.139 227.769 230.279C234.304 229.418 240.392 234.225 241.298 241.105Z"
      stroke="#3B4168"
      strokeWidth="2"
    />
    <circle cx="379" cy="142.788" r="7" stroke="#3B4168" strokeWidth="2" />
    <path
      d="M419.737 42.929L411.455 73.8386"
      stroke="#3B4168"
      strokeWidth="2"
    />
    <path
      d="M400.142 54.2427L431.051 62.5249"
      stroke="#3B4168"
      strokeWidth="2"
    />
    <path
      d="M11.6234 159.957C10.8667 159.175 10.4884 158.785 10.3541 158.284C10.2198 157.782 10.3522 157.255 10.6169 156.2L12.5095 148.658L14.6416 141.18C14.9398 140.134 15.0889 139.611 15.4558 139.244C15.8227 138.877 16.3456 138.728 17.3915 138.43L24.8697 136.297L32.412 134.405C33.4669 134.14 33.9944 134.008 34.4955 134.142C34.9967 134.276 35.3873 134.655 36.1685 135.411L41.7541 140.822L47.1643 146.407C47.9209 147.188 48.2993 147.579 48.4336 148.08C48.5679 148.581 48.4355 149.109 48.1708 150.164L46.2782 157.706L44.1461 165.184C43.8479 166.23 43.6988 166.753 43.3319 167.12C42.965 167.487 42.4421 167.636 41.3961 167.934L33.918 170.066L26.3757 171.959C25.3208 172.224 24.7933 172.356 24.2922 172.222C23.791 172.087 23.4004 171.709 22.6192 170.952L17.0336 165.542L11.6234 159.957Z"
      stroke="#3B4168"
      strokeWidth="2"
    />
    <circle
      cx="29.3934"
      cy="153.182"
      r="6"
      transform="rotate(-15 29.3934 153.182)"
      stroke="#3B4168"
      strokeWidth="2"
    />
  </svg>
);
