import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { IModel, ModelStage } from '@unione-pro/unione.assmnt.sdk.webapp';
import { appTheme } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/theme';
import { debounce } from 'lodash';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { CheckedIcon } from '../../components/icons/checked';
import { ChevronsIcon } from '../../components/icons/chevrons';
import { FolderIcon } from '../../components/icons/folder';
import { SearchIcon } from '../../components/icons/search';
import { Input } from '../../components/input';
import { Menu } from './sidebar-menu.view';
import { IMenu, IMenuOption, ISidebarMenuProps, ISidebarProps } from './sidebar.models';
import { useSidebarStyles } from './sidebar.styles';

export const Sidebar: FC<ISidebarProps> = observer(({ sidebarWidth, startResizing }) => {
  const appStore = useContext(appStoreContext);
  const { changeParams, draft, published, draftParams, publishedParams } = appStore.modelSidebar;
  const {
    list: draftList,
    total: draftTotal,
    loading: draftLoading,
  } = draft;
  const {
    list: publishedList,
    total: publishedTotal,
    loading: publishedLoading,
  } = published;

  const [openMenus, setOpenMenus] = useState<Array<string | undefined>>([]);
  const [menuActive, setMenuActive] = useState<boolean>(false);
  const classes = useSidebarStyles({ sidebarWidth, menuActive });

  const searchByName = debounce((name: string) => {
    appStore.modelSidebar.resetPublished();
    appStore.modelSidebar.resetDraft();

    changeParams({ ...publishedParams, page: 1, name }, ModelStage.published);
    changeParams({ ...draftParams, page: 1, name }, ModelStage.draft);
  }, 500);

  const requestHandler: ISidebarMenuProps['sendRequest'] = (stage, page): void => {
    if (stage === ModelStage.published) {
      changeParams({ ...publishedParams, page }, stage);
    }

    if (stage === ModelStage.draft) {
      changeParams({ ...draftParams, page }, stage);
    }
  };

  const openHandler = (stage: ModelStage): void => {
    setOpenMenus((prev) => (
      !!prev.length
        ? prev.map((prevStage) => (prevStage === stage ? undefined : stage))
        : [stage]
    ));
  };

  const getLinksList = (data: IModel[]): IMenuOption<IModel>[] | undefined => data
    .map((model) => ({
      label: model.common_params.name,
      value: model,
      id: model._id,
    }));

  const menuTabs = useMemo<IMenu<IModel>[]>(() => [
    {
      title: 'Опубликованные',
      stage: ModelStage.published,
      icon: <CheckedIcon />,
      options: getLinksList(publishedList),
    },
    {
      title: 'Черновики',
      stage: ModelStage.draft,
      icon: <FolderIcon />,
      options: getLinksList(draftList),
    },
  ], [draftList, publishedList]);

  const onChangeMenuActive = (): void => {
    setMenuActive(!menuActive);
  };

  // useEffect для сброса активности sidebar-a
  useEffect(() => {
    const updateWindowDimensions = (): void => {
      if (appTheme.breakpoints.lg <= window.innerHeight) {
        setMenuActive(false);
      }
    };

    window.addEventListener('resize', updateWindowDimensions);

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <aside className={classes.root}>
      <div className={classes.container}>
        <div className={classes.content}>
          <Input
            labelClassName={classes.search}
            onChange={(event): void => searchByName(event.target.value)}
            icon={<SearchIcon height={16} width={16} />}
            hintText={
              !publishedList.length && !draftList.length && !draftLoading && !publishedLoading
                ? (<p className={classes.sidebarEmpty}>Модели не найдены</p>)
                : undefined
            }
            placeholder="Поиск по моделям"
          />
          {menuTabs.map(
            ({ options, title, stage, icon }): JSX.Element => {
              const isPublished = stage === ModelStage.published;
              const loading = isPublished ? publishedLoading : draftLoading;
              const currentPage = isPublished ? publishedParams.page : draftParams.page;
              const total = isPublished ? publishedTotal : draftTotal;
              const showTotal = !!(
                isPublished ? publishedParams.name : draftParams.name
              )?.length;
              const limit = isPublished
                ? publishedParams.limit
                : draftParams.limit;

              return (
                <Menu<IModel<string>>
                  page={currentPage}
                  key={title}
                  open={openMenus.includes(stage)}
                  options={options}
                  title={title}
                  stage={stage}
                  icon={icon}
                  sendRequest={requestHandler}
                  setOpen={openHandler}
                  loading={loading}
                  total={total}
                  limit={limit}
                  showTotal={showTotal}
                />
              );
            },
          )}
        </div>
      </div>
      <div className={classes.switcher_wrapper}>
        <div className={classes.switcher} onClick={onChangeMenuActive}>
          <ChevronsIcon />
        </div>
      </div>
      <div className={classes.blur} onClick={onChangeMenuActive} />
      <div className={classes.resizeBlock} onMouseDown={startResizing} />
    </aside>
  );
});
