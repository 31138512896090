import { FC, ReactElement, useMemo } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Button } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import { COURSE_STAGE_COLORS, DEFAULT_STAGE_COLOR } from '../../../../constants/course-stages.constants';
import { States } from '../../../../models/entities';
import { ColorPicker } from '../../../components/color-picker';
import { PlusIcon } from '../../../components/icons/plus';
import { Input } from '../../../components/input';
import { useFormCommonStyles } from '../use-styles';
import { requiredCheck } from '../utils';
import { useStagesStyles } from './use-styles';

const STAGES = 'stages';

export const Stages: FC = () => {
  const { fields } = useFieldArray(STAGES);
  const commonClasses = useFormCommonStyles();
  const classes = useStagesStyles();

  const handleAddStage = (): void => {
    fields.push(DEFAULT_STAGE_COLOR);
  };

  const isAddStageDisabled = useMemo(() => fields.length === 10, [fields.length]);

  return (
    <Paper className={commonClasses.paper}>
      <h3 className={commonClasses.blockTitle}>Этапы оценки</h3>

      <div className={classes.stages}>
        {fields.map((fieldName, index) => (
          <div key={fieldName} className={commonClasses.fields}>
            <Field
              name={`${fieldName}.name`}
              validate={requiredCheck}
              render={({ input, meta: { error, touched } }): ReactElement => (
                <Input
                  {...input}
                  id={`${STAGES}.${index}.name`}
                  labelClassName={commonClasses.field}
                  label="Название этапа"
                  name={`${fieldName}.name`}
                  hintText={touched && error}
                  state={(touched && error) ? States.Error : States.Default}
                />
              )}
            />
            <Field
              name={`${fieldName}.color`}
              render={({ input }): ReactElement => (
                <ColorPicker
                  {...input}
                  label="Цвет этапа"
                  colors={COURSE_STAGE_COLORS}
                  onRemoveStage={(): void => fields.remove(index)}
                  hideRemoveBtn={fields.length === 1}
                />
              )}
            />
          </div>
        ))}

        <hr />

        <Button
          size="none"
          variantStyle="standard"
          className={cn(classes.btn, { [classes.btnDisabled]: isAddStageDisabled })}
          onClick={handleAddStage}
          disabled={isAddStageDisabled}
        >
          <PlusIcon /> Добавить этап
        </Button>
      </div>
    </Paper>
  );
};
