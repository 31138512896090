import { ILoggerStore } from '../models/stores/logger.store';
import { IModelDetailStore } from '../models/stores/model-detail.store';
import { IRootStore } from '../models/stores/root.store';
import { ConfigStore } from './config.store';
import { DictionaryStore } from './dictionary.store';
import { LoggerStore } from './logger.store';
import { ModelDetailStore } from './model-detail.store';
import { ModelFormStore } from './model-form.store';
import { ModelListStore } from './model-list.store';
import { ModelSidebarStore } from './model-sidebar.store';
import { RoleStore } from './role.store';
import { UserStore } from './user.auth.store';

export class RootStore implements IRootStore {

  public readonly config: ConfigStore;
  public readonly user: UserStore;
  public readonly role: RoleStore;
  public readonly dictionary: DictionaryStore;
  public readonly modelForm: ModelFormStore;
  public readonly modelList: ModelListStore;
  public readonly modelSidebar: ModelSidebarStore;
  public readonly logger: ILoggerStore;
  public readonly modelDetail: IModelDetailStore;

  public get today(): Date {
    return new Date();
  }

  constructor() {
    this.config = new ConfigStore();
    this.user = new UserStore(this);
    this.role = new RoleStore(this);
    this.dictionary = new DictionaryStore(this);
    this.modelList = new ModelListStore(this);
    this.modelForm = new ModelFormStore(this);
    this.modelSidebar = new ModelSidebarStore(this);
    this.logger = new LoggerStore(this);
    this.modelDetail = new ModelDetailStore(this);
  }

}
