import ru from 'date-fns/esm/locale/ru';
import format from 'date-fns/format';

export const formatDate = (
  time?: string | number | Date,
  dateformat = 'dd.MM.yyyy',
): string => {
  if (!time) {
    return '';
  }

  return format(new Date(time), dateformat, {
    locale: ru,
  });
};
