import { Competencies, ICourseModelLevel, ModelLevel } from '@unione-pro/unione.assmnt.sdk.webapp';
import _uniqueId from 'lodash/uniqueId';
import { LEVEL_RANGES_BY_TEST_LEVEL } from '../constants/model.constants';
import { PreparedLevels } from '../models/stores/dictionary.store';

type Option = {
  label: string;
  value: string;
};

export const generateLabelByValue = (options?: Option[]): PreparedLevels => {
  if (!options) {
    return {};
  }

  return options.reduce(
    (acc, current) => ({
      ...acc,
      [current.value]: current.label,
    }),
    {},
  );
};

type LevelItem = {
  value: ModelLevel | 'basic_level';
};

export const generateDefaultLevel = ({ value }: LevelItem): ICourseModelLevel => ({
  type: value as Competencies,
  ...LEVEL_RANGES_BY_TEST_LEVEL[value],
});
