import { createUseStyles } from 'react-jss';

export const useTabStyles = createUseStyles(
  (theme) => ({
    tab: {
      width: 'fit-content',
      padding: '4px 16px',
      color: 'white',
      fontWeight: theme.typography.fontWeightLight,
      fontSize: '14px',
      lineHeight: '140%',
      borderRadius: '4px',
    },
    published: {
      background: theme.palette.helsinki.main,
    },
    level: {
      background: theme.palette.tokyo.main,
    },
    draft: {
      background: theme.palette.gray.contrastText,
    },

  }),
);
