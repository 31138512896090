import { FC } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Link, useMatch } from 'react-router-dom';
import { scroller } from 'react-scroll';
import { ModelStage } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { BrowserRoute } from '../../../../routes/browser.routes';
import { CloseIcon } from '../../../components/icons/close';
import { DoneIcon } from '../../../components/icons/done';
import { SaveIcon } from '../../../components/icons/save';
import { getInvalidFieldId } from '../utils';
import { useToolbarStyles } from './use-styles';

export const FormToolbar: FC = () => {
  const classes = useToolbarStyles();
  const form = useForm();
  const { submitting, errors, hasValidationErrors, values } = useFormState();
  const editMatchData = useMatch(BrowserRoute.updateModel);

  const handleShowErrorNotification = (): void => {
    if (!hasValidationErrors || !errors) {
      return;
    }

    const keys = Object.keys(values); // чтобы не потерять порядок полей

    const foundKey = keys.find((key) => {
      const errorField = errors[key];

      if (!errorField) {
        return false;
      }

      if (!Array.isArray(errorField)) {
        return true;
      }

      return Boolean(errorField.filter(Boolean).length);
    }) || '';

    const invalidFieldId = getInvalidFieldId(errors[foundKey], foundKey);

    scroller.scrollTo(invalidFieldId, {
      duration: 400,
      smooth: 'easeInOutQuart',
      offset: -200,
    });
  };

  const isEditMode = Boolean(editMatchData);

  const handleSubmit = (stage: ModelStage): void => {
    form.change('stage', stage);

    if (!isEditMode) {
      form.change('_id', undefined); // удаляем id, если это не редактирование
    }

    form.submit();
  };

  const handleSave = (): void => {
    handleShowErrorNotification();
    handleSubmit(ModelStage.draft);
  };

  const handlePublish = (): void => {
    handleShowErrorNotification();
    handleSubmit(ModelStage.published);
  };

  return (
    <Paper className={classes.actions}>
      <Link to={BrowserRoute.index} className={classes.button}>
        <CloseIcon />
        <span>Закрыть</span>
      </Link>
      <button className={classes.button} onClick={handleSave} disabled={submitting}>
        <SaveIcon />
        <span>{isEditMode ? 'Сохранить' : 'В черновик'}</span>
      </button>
      <button className={classes.button} onClick={handlePublish} disabled={submitting}>
        <DoneIcon />
        <span>Опубликовать</span>
      </button>
    </Paper>
  );
};
