export enum States {
  Default = 'default',
  Warning = 'warning',
  Success = 'success',
  Error = 'error',
}

export enum LevelsTypes {
  BASIC = 'basic_level',
  NORMAL = 'normal_level',
  ADVANCED = 'advanced_level',
  EXPERT = 'expert_level',
}

export enum TestType {
  COMPETENCY = 'competency',
  SPHERE = 'sphere',
}

export enum QuestionOutput {
  BY_ONE = 'by_one',
  LIST = 'list',
}

export enum TestLevel {
  NORMAL_LEVEL = 'normal_level',
  ADVANCED_LEVEL = 'advanced_level',
  EXPERT_LEVEL = 'expert_level',
}

export enum TestTag {
  SPHERE = 'sphere',
  COMPETENCY = 'competency',
  COMPETENCY_LEVELS = 'competency_level',
}

export enum TestCaseTag {
  SPHERE = 'sphere',
  COMPETENCY = 'competency',
}

export enum DisplayResults {
  COMPETENCY_ONLY = 'competency_only',
  SPHERE_ONLY = 'sphere_only',
  COMPETENCY_SPHERE = 'competency_sphere',
}

export enum RegistrationType {
  LINK = 'link',
  LIST = 'list',
}

export interface IEnumValues<TData = string> {
  label: string;
  value: TData;
}
