import { sdkSendLog } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable } from 'mobx';
import { ILoggerStore, LOGGER_ACTION } from '../models/stores/logger.store';
import { IRootStore } from '../models/stores/root.store';

export class LoggerStore implements ILoggerStore {

  public readonly rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public sendLog = async(action: LOGGER_ACTION, request: object): Promise<void> => {
    const { loggerAPI, loggerAPIKey } = this.rootStore.config;

    if (!this.rootStore.role.data || !loggerAPI || !loggerAPIKey) {
      return;
    }

    await sdkSendLog({
      baseURL: loggerAPI,
      headers: {
        'x-api-key': loggerAPIKey,
      },
      token: this.rootStore.user.token,
      data: {
        uid: this.rootStore.role.data.id,
        act: action,
        ts: new Date(),
        req: request,
      },
    });
  };

}
