import { createUseStyles } from 'react-jss';

export const useSettingsBlockStyles = createUseStyles((theme) => ({
  root: {
    padding: '32px 40px 40px',
    borderRadius: '8px',
    background: theme.background.background,
  },
  header: {
    margin: '0 0 24px',
    padding: 0,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '18px',
    lineHeight: '24px',
    color: theme.palette.gloomyBlue.main,
  },
  content: {
    width: '100%',
  },
}));
