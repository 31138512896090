import { createUseStyles } from 'react-jss';

export const useColorPickerStyles = createUseStyles(
  (theme) => ({
    root: {
      height: '100%',
      width: '100%',
      gridColumn: 'span 2',
    },
    label_text: {
      color: theme.palette.secondaryText.main,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: 14,
      lineHeight: '20px',
      marginBottom: 4,
    },
    colors: {
      marginTop: 8,
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    colorWrap: {
      border: {
        width: 2,
        color: 'transparent',
        radius: '50%',
      },
      padding: 2,

      '&:hover': {
        borderColor: theme.palette.disableGray.main,
      },
    },
    color: {
      padding: 10,
      cursor: 'pointer',
      borderRadius: '50%',
    },
    active: {
      borderColor: theme.palette.disableGray.main,
    },
    delete: {
      color: theme.palette.secondaryText.main,
      cursor: 'pointer',
      marginLeft: 'auto',
    },
  }),
);
