import { createUseStyles } from 'react-jss';

export const useInputStyles = createUseStyles(
  (theme) => ({
    root: {
      position: 'relative',
    },
    input: {
      color: theme.palette.gloomyBlue.main,
      padding: {
        top: 10,
        bottom: 10,
      },
      borderWidth: '1px',
      borderRadius: '0.25rem',
      borderStyle: 'solid',
      outline: '2px solid transparent',
      outlineOffset: '2px',
      lineHeight: '1rem',
      width: '100%',
    },
    input_left: {
      paddingRight: 16,
      paddingLeft: 31,
    },
    input_right: {
      paddingRight: 31,
      paddingLeft: 16,
    },
    colorDefault: {
      borderColor: '#E4E7F2',
    },
    helperText: {
      margin: '0',
      marginTop: '0.25rem',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: '1.25rem',
      width: '100%',
    },
    colorError: {
      borderColor: theme.palette.error.main,
    },
    colorWarning: {
      borderColor: theme.palette.warning.main,
    },
    colorSuccess: {
      borderColor: theme.palette.success.main,
    },
    label_text: {
      color: theme.palette.secondaryText.main,
      fontWeight: theme.typography.fontWeightLight,
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '4px',
    },
    inputWrapper: {
      position: 'relative',
    },
    icon_inactive: {
      display: 'none',
    },
    icon_active: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon_right: {
      top: 0,
      right: 0,
      position: 'absolute',
      height: '100%',
      color: theme.palette.gray.contrastText,
      padding: {
        top: 10,
        bottom: 10,
        left: 0,
        right: 16,
      },
    },
    icon_left: {
      top: 0,
      left: 0,
      position: 'absolute',
      height: '100%',
      color: theme.palette.gray.contrastText,
      padding: {
        top: 10,
        bottom: 10,
        left: 16,
        right: 0,
      },
    },
  }),
);
