import { FC, HTMLProps, useCallback, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { ChevronsIcon } from '../icons/chevrons';
import { ISelectOption, ISelectProps } from './select.models';
import { useSelectStyles } from './select.styles';

export const Select: FC<ISelectProps> = ({
  value,
  icon,
  options,
  defaultValue,
  size = 'md',
  label,
  className,
  dropTop = false,
  dropRight = false,
  error,
  disabled,
  withShadow = true,
  onBlur,
  onChange,
  onChangeValue,
  ...restProps
}) => {
  const classes = useSelectStyles();

  const wrapperRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const getOptionLabel = useCallback((option: ISelectOption): string => {
    if (typeof option === 'string') {
      return option;
    }
    if (typeof option === 'number' || typeof option === 'boolean') {
      return option.toString();
    }
    return option.label;
  }, []);

  const [selected, setSelected] = useState<ISelectOption>(
    value ?? defaultValue ?? options[0],
  );
  const [isOpen, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  const selectedLabel = getOptionLabel(selected);

  const wrapperProps: HTMLProps<HTMLDivElement> = {
    ...restProps,
    ref: wrapperRef,
    tabIndex: 1,
    onBlur: (event) => {
      setOpen(false);

      if (typeof onBlur === 'function') {
        onBlur(event);
      }
    },
  };

  const toggleDropdown = (): void => {
    if (disabled) {
      return;
    }

    setOpen((prev) => !prev);
  };

  const onOptionChange = (option: ISelectOption): void => {
    if (disabled) {
      return;
    }

    setSelected(option);
    const selectedOption = typeof option === 'object' ? option.value! : option;

    if (typeof onChange === 'function') {
      onChange(selectedOption);
    }

    if (onChangeValue) {
      onChangeValue(selectedOption);
    }
  };

  const selectWrapperCN = cn(
    classes.wrapper,
    {
      [classes.wrapper_withShadow]: withShadow,
    },
    className,
  );

  const selectedLabelSizeClasses = {
    md: classes.selected_label_md,
    sm: classes.selected_label_sm,
  };

  const selectedLabelCN = cn(classes.selected_label, {
    [classes.selected_label_error]: error,
    [classes.selected_label_disabled]: disabled,
    [selectedLabelSizeClasses[size]]: !!size,
  });

  const dropDownCN = cn(classes.dropdown, {
    [classes.dropTop]: dropTop,
    [classes.dropBottom]: !dropTop,
    [classes.dropRight]: dropRight,
    [classes.dropLeft]: !dropRight,
    [classes.visible]: isOpen,
    [classes.invisible]: !isOpen,
  });

  const iconSizeClasses = {
    sm: classes.icon_sm,
    md: classes.icon_md,
  };

  const iconCN = cn(classes.icon, {
    [iconSizeClasses[size]]: !!size,
    [classes.icon_rotate_180]: isOpen,
  });

  return (
    <div {...wrapperProps} className={selectWrapperCN}>
      {label && <label className={classes.label}>{label}</label>}
      <div className={classes.root} onClick={toggleDropdown}>
        <div className={selectedLabelCN}>
          <span>{selectedLabel}</span>
          {icon ?? <ChevronsIcon className={iconCN} />}
        </div>
        <div ref={dropdownRef} className={dropDownCN}>
          <div className={classes.dropdown__content}>
            {options.map((option) => {
              const optionLabel = getOptionLabel(option);
              const isCurrent = optionLabel === selectedLabel;

              const optionClasses = cn(classes.option, {
                [classes.option_active]: isCurrent,
              });

              const onOptionClick = (): void => {
                onOptionChange(option);
              };

              return (
                <div
                  key={optionLabel}
                  className={optionClasses}
                  onClick={onOptionClick}
                >
                  {optionLabel}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {error && <div className={classes.error_text}>{error}</div>}
    </div>
  );
};
