import { FC } from 'react';
import cn from 'classnames';
import { ISettingsBlockProps } from './settings-block.models';
import { useSettingsBlockStyles } from './settings-block.styles';

export const SettingsBlock: FC<ISettingsBlockProps> = ({
  title,
  children,
  className,
  disableDefaultClassName,
  ...other
}) => {
  const classes = useSettingsBlockStyles();

  const rootCN = cn(
    disableDefaultClassName ? null : classes.root,
    className,
  );

  return (
    <div className={rootCN} {...other}>
      {title && (
        <h3 className={classes.header}>
          {title}
        </h3>
      )}
      <div className={classes.content}>
        {children}
      </div>
    </div>
  );
};
