import { FC, ReactElement } from 'react';
import { Field, useForm } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { ITestOption, TestType } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import cn from 'classnames';
import Switch from 'rc-switch';
import {
  QUESTION_OUTPUT_FIELD_NAME,
  TAG_BY_TEST_TYPE,
  TEST_TYPE_FIELD_NAME,
  WEIGHT_QUESTIONS_FIELD_NAME,
} from '../../../../constants/model.constants';
import { useAppStore } from '../../../../stores/context.store';
import { IRadioOption, RadioBlock } from '../../../components/radio-block';
import { ModelFormComponentProps } from '../interfaces';
import { useFormCommonStyles } from '../use-styles';
import { useFieldsStyles } from './use-styles';

export const TestOptions: FC<ModelFormComponentProps> = ({ testOptions }) => {
  const commonClasses = useFormCommonStyles();
  const fieldClasses = useFieldsStyles();
  const { fields } = useFieldArray(WEIGHT_QUESTIONS_FIELD_NAME);
  const form = useForm();
  const appStore = useAppStore();

  const { questionTypeLabels } = appStore.dictionary;

  const values: ITestOption[] = fields.value;

  const getLabel = (index: number): string => {
    const questionType = values[index].type;

    return questionTypeLabels[questionType];
  };

  const handleChangeTestType = (option: IRadioOption): void => {
    form.change(TEST_TYPE_FIELD_NAME, option.value);

    // eslint-disable-next-line no-restricted-syntax
    fields.forEach((fieldName, index) => {
      const questionType = values[index].type;
      const tagsByQuestionType = TAG_BY_TEST_TYPE[option.value as TestType];

      form.change(`${fieldName}.tag`, tagsByQuestionType[questionType]);
    });
  };

  return (
    <Paper className={commonClasses.paper}>
      <h3 className={commonClasses.blockTitle}>Параметры оценки</h3>

      <div className={cn(fieldClasses.flex, fieldClasses.marginBottom24)}>
        <Field
          name={TEST_TYPE_FIELD_NAME}
          render={({ input }): ReactElement => (
            <RadioBlock
              title={<div className={commonClasses.marginBottom8}>Тип оценки</div>}
              value={input.value}
              options={testOptions.testTypes}
              onChange={handleChangeTestType}
              className={fieldClasses.flexGrow}
            />
          )}
        />
        <Field
          name={QUESTION_OUTPUT_FIELD_NAME}
          render={({ input: { value, onChange } }): ReactElement => (
            <RadioBlock
              title={<div className={commonClasses.marginBottom8}>Выдача вопросов</div>}
              value={value}
              options={testOptions.questionOutputs}
              onChange={(option): void => onChange(option.value)}
              className={fieldClasses.flexGrow}
            />
          )}
        />
      </div>

      <div className={fieldClasses.label}>Типы задания в тесте</div>
      <div className={fieldClasses.fields}>
        {fields.map((fieldName, index) => (
          <Field
            key={fieldName}
            name={`${fieldName}.is_enabled`}
            render={({ input: { value, onChange } }): ReactElement => (
              <label className={fieldClasses.field}>
                <div className={commonClasses.secondary}>{getLabel(index)}</div>
                <Switch
                  onChange={onChange}
                  checked={value}
                  className={commonClasses.switcher}
                />
              </label>
            )}
          />
        ))}
      </div>
    </Paper>
  );
};
