import { FC } from 'react';
import { Link } from 'react-router-dom';
import { BrowserRoute } from '../../../routes/browser.routes';
import { Button } from '../../components/button';
import { PlusIcon } from '../../components/icons/plus';
import { useIntroStyles } from './intro.styles';

export const Intro: FC = () => {
  const classes = useIntroStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.content}>
          <h1 className={classes.title}>Модели оценочных сессий</h1>
          <p className={classes.paragraph}>
          Здесь вы можете создать модели для оценочных сессий.
          </p>
          <p className={classes.paragraph}>
          Для добавления новой модели нажмите на "Добавить модель".
          </p>
          <p className={classes.paragraph}>
            Для просмотра параметров модели, выберите необходимую модель в сайдбаре.
          </p>

          <Link
            to={BrowserRoute.createModel}
            className={classes.button}
          >
            <Button size="sm">
              <PlusIcon />
            Добавить модель
            </Button>
          </Link>
        </div>


        <div className={classes.imageWrapper}>
          <img src={`${process.env.PUBLIC_URL}/images/competences.png`} alt="competency" className={classes.image} />
        </div>
      </div>
    </div>
  );
};
