import { FC, SVGProps } from 'react';

export const RefreshIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.33337 16.6667V10.8333H9.16671L6.48587 13.5167C7.41084 14.4625 8.67709 14.997 10 15C12.1162 14.9969 14.0013 13.6618 14.7067 11.6667H14.7217C14.8172 11.3955 14.8894 11.1167 14.9375 10.8333H16.6142C16.1943 14.1666 13.3596 16.6665 10 16.6667H9.99171C8.22401 16.6719 6.52768 15.9698 5.28087 14.7167L3.33337 16.6667ZM5.06171 9.16665H3.38504C3.80483 5.83466 6.63754 3.33511 9.99587 3.33329H10C11.7681 3.32763 13.4648 4.02985 14.7117 5.28332L16.6667 3.33329V9.16665H10.8334L13.5184 6.48332C12.5924 5.53636 11.3245 5.00177 10 4.99999C7.88383 5.00311 5.99877 6.33814 5.29337 8.33332H5.27837C5.18217 8.60424 5.10995 8.88309 5.06254 9.16665H5.06171Z"
      fill="white"
    />
  </svg>
);
