import { createUseStyles } from 'react-jss';

export const useActionsStyles = createUseStyles((theme) => ({
  root: {
    position: 'sticky',
    top: 100,
    display: 'flex',
    height: 'min-content',
    width: '6.5rem',
    flexDirection: 'column',
    rowGap: 36,
    borderRadius: '0.25rem',
    background: 'white',
    padding: '1.75rem 0.5rem',
  },
  posSticky: {
    position: '-webkit-sticky',
    top: 100,
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: 8,
    fontSize: '0.75rem',
    lineHeight: '1rem',
    color: theme.palette.secondaryText.main,
    padding: 0,

    '&:hover': {
      color: theme.palette.black.main,
    },
  },
}));
