import { FC } from 'react';
import cn from 'classnames';
import CheckBox from 'rc-checkbox';
import { HintText } from '../hint-text';
import { IRadioBlockProps } from './radio-block.models';
import { useRadioBlockStyles } from './radio-block.styles';
import { useRadioInputStyles } from './radio-input.styles';

export const RadioBlock: FC<IRadioBlockProps> = ({
  title,
  state,
  hintText,
  options = [],
  value,
  onChange,
  className,
  ...other
}) => {
  const classes = useRadioBlockStyles();
  const radioInputClasses = useRadioInputStyles();

  const rootCN = cn(classes.root, className);

  return (
    <div className={rootCN} {...other}>
      {title && (
        <p className={classes.title}>
          {title}
        </p>
      )}
      <div className={classes.content}>
        {options.map((option, index) => (
          <label className={classes.option}>
            <CheckBox
              key={index}
              value={option.value}
              type="radio"
              name="radio"
              checked={value === option.value}
              onChange={(): void => onChange?.(option)}
              className={radioInputClasses.root}
            />
            <p className={classes.label}>{option.label}</p>
          </label>
        ))}
      </div>
      <HintText state={state} hintText={hintText} />
    </div>
  );
};
