import { FC } from 'react';
import { Button, Modal } from '@unione-pro/unione.assmnt.ui-kit.webapp';
import { CloseIcon } from '../../../components/icons/close';
import { ErrorModelIcon } from '../../../components/icons/error-model';
import { WarningModelIcon } from '../../../components/icons/warning-model';
import { IRemoveModalProps, RemoveModalType } from '../model-readonly.model';
import { useRemoveModelModalStyles } from './model-remove-modal.styles';

const REMOVE_MODAL_CONTENT = {
  [RemoveModalType.info]: {
    icon: <ErrorModelIcon width={40} height={40} />,
    title:
      'Модель удалить нельзя, так&nbsp;как&nbsp;она&nbsp;привязана к активной оценочной&nbsp;сессии',
  },
  [RemoveModalType.confirm]: {
    icon: <WarningModelIcon width={64} height={64} />,
    title: 'Вы уверены, что хотите удалить&nbsp;модель?',
  },
};

export const RemoveModal: FC<IRemoveModalProps> = ({
  type,
  isOpen,
  onOk,
  onCancel,
}) => {
  const classes = useRemoveModelModalStyles();
  const showActions = type === RemoveModalType.confirm;

  return (
    <Modal className={classes.root} open={isOpen}>
      <div className={classes.closeIcon} onClick={onCancel}>
        <CloseIcon />
      </div>
      <div className={classes.body}>
        <div className={classes.modelIcon}>
          {REMOVE_MODAL_CONTENT[type].icon}
        </div>
        <div className={classes.title} dangerouslySetInnerHTML={{ __html: REMOVE_MODAL_CONTENT[type].title }} />
      </div>
      {showActions && (
        <div className={classes.actions}>
          <Button className={classes.actionBtn} size="sm" variant="primary" onClick={onOk}>
            Да
          </Button>
          <Button className={classes.actionBtn} size="sm" variant="outlined" variantStyle="standard" onClick={onCancel}>
            Отмена
          </Button>
        </div>
      )}
    </Modal>
  );
};
