import { createUseStyles } from 'react-jss';

export const useIntroStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '468px',
    borderRadius: '0.25rem',
    background: theme.background.background,
    boxShadow: theme.shadow.box,
  },
  title: {
    color: theme.palette.gloomyBlue.main,
    fontWeight: theme.typography.fontWeightBolder,
    fontSize: '2rem',
    margin: 0,
  },
  paragraph: {
    color: theme.palette.gray.contrastText,
    fontSize: '1.125rem',
    lineHeight: '1.5rem',
    margin: 0,
    marginTop: '1rem',
  },
  imageWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    overflow: 'hidden',
  },
  button: {
    marginTop: '56px',
  },
  image: {
    height: '100%',
    objectPosition: 'left',
    objectFit: 'cover',
  },
  content: {
    width: '100%',
    padding: '80px 0 80px 80px',
    maxWidth: 621,
    minWidth: 420,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    flexGrow: 1,
  },

  [`@media(max-width: ${theme.breakpoints.lg}px)`]: {
    paragraph: {
      fontSize: '1rem',
    },
    root: {
      padding: '0.625rem',
    },
    imageWrapper: {
      display: 'none',
    },
    content: {
      padding: '20px 40px 20px',
      maxWidth: '100%',
      minWidth: 0,
    },
  },
}));
