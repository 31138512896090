import { FC, ReactElement, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useMatch, useNavigate, useParams } from 'react-router-dom';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { useMount, useUnmount } from 'ahooks';
import cn from 'classnames';
import arrayMutators from 'final-form-arrays';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useAppStore } from '../../../stores/context.store';
import { Loader } from '../../components/loader';
import { RadioBlock } from '../../components/radio-block';
import {
  FieldsDisplay,
  QuestionsCount,
  QuestionsTime,
  WeightOptions,
  Stages,
  TestOptions,
  LevelRanges,
  CommonParams,
  FormToolbar,
} from './components';
import { useFormCommonStyles, useModelFormStyles } from './use-styles';

export const ModelForm: FC = observer(() => {
  const commonClasses = useFormCommonStyles();
  const classes = useModelFormStyles();
  const navigate = useNavigate();
  const { dictionary, modelForm } = useAppStore();
  const { id: modelId } = useParams();
  const editMatchData = useMatch(BrowserRoute.updateModel);

  const isEditMode = Boolean(editMatchData);

  const { data: testOptions } = dictionary;
  const { formSubmit, initialValues, redirectPath, reset, getModel, loading } = modelForm;

  useEffect(() => {
    if (redirectPath) {
      navigate(redirectPath);
    }
  }, [redirectPath, navigate]);

  useMount(() => {
    if (modelId) {
      getModel(modelId);
    }
  });

  useUnmount(reset);

  if (!testOptions) {
    return null;
  }

  return (
    <>
      {loading && <div className={classes.loader}><Loader /></div>}
      <h1 className={classes.title}>{`${isEditMode ? 'Редактирование' : 'Создание'} модели оценочных сессий`}</h1>

      <Form
        initialValues={initialValues}
        mutators={{ ...arrayMutators }}
        onSubmit={formSubmit}
        render={(): ReactElement => (
          <div className={classes.wrapper}>
            <div className={classes.form}>
              <CommonParams testOptions={testOptions} />

              <Stages />

              <FieldsDisplay />

              <TestOptions testOptions={testOptions} />

              <QuestionsCount />

              <QuestionsTime />

              <Paper className={cn(commonClasses.paper, commonClasses.column)}>
                <WeightOptions />
                <LevelRanges />
              </Paper>

              <Paper className={commonClasses.paper}>
                <h3 className={commonClasses.blockTitle}>Отображение результатов</h3>

                <Field
                  name="display_results"
                  render={({ input: { value, onChange } }): ReactElement => (
                    <RadioBlock
                      value={value}
                      options={testOptions.displayResults}
                      onChange={(option): void => onChange(option.value)}
                    />
                  )}
                />
              </Paper>
            </div>

            <FormToolbar />
          </div>
        )}
      />
    </>
  );
});
