import { IModel, sdkGetModelsList } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import { IModelListStore } from '../models/stores/model-list.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';

export class ModelListStore implements IModelListStore {

  public readonly rootStore: IRootStore;

  public data: IModel[] | null = null;
  public total: number = 0;
  public loading: boolean = false;
  public error?: string = undefined;


  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public async get(): Promise<void> {
    try {
      runInAction(() => {
        this.data = null;
        this.loading = true;
        this.error = undefined;
        this.total = 0;
      });

      const response = await sdkGetModelsList({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
        data: {
          limit: 100,
        },
      });

      runInAction(() => {
        this.data = response.items;
        this.total = response.total;
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

}
