import { FC, useMemo } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react';
import { formatDate } from '../../../shared/human-date';
import { useAppStore } from '../../../stores/context.store';
import { SettingsBlock } from '../../components/settings-block';
import { Tab } from '../../components/tab';
import { IModelReadonlyProps } from './model-readonly.model';
import { useModelReadonlyStyles } from './model-readonly.styles';

export const ModelReadonly: FC<IModelReadonlyProps> = observer(({
  model,
}) => {
  const classes = useModelReadonlyStyles();
  const appStore = useAppStore();

  const { levelLabels, stageLabels } = appStore.dictionary;

  const commonParamsOptions = useMemo(() => [
    {
      title: 'Название модели',
      placeholder: 'Введите название модели',
      value: model.commonParams.name,
    },
    {
      title: 'Уровень, до которого проводится оценка',
      value: model.commonParams.level,
    },
    {
      title: 'Кол-во попыток на этап',
      value: model.commonParams.attemptsCount,
    },
    {
      title: 'Макс кол-во тестируемых',
      value: model.commonParams.testersCount,
    },
    {
      title: 'Тип регистрации',
      value: model.commonParams.registrationType,
    },
    {
      title: 'Сертификаты по завершению',
      value: model.commonParams.certificates,
    },
    {
      title: 'Отображение вопросов после завершения оценки',
      value: model.fieldsDisplay.show_questions ? 'Отображать' : 'Скрыть',
    },
  ], [model.commonParams]);

  const testOption = useMemo(() => [
    {
      title: 'Тип оценки',
      value: model.testOptions.testType,
    },
    {
      title: 'Выдача вопросов',
      value: model.testOptions.output,
    },
    {
      title: 'Типы задания в тесте',
      value: model.weightQuestions.filter((el) => el.count).map((question) => <div key={`test-types-${question.key}`}>{question.type}</div>),
    },
  ], [model.testOptions, model.weightQuestions]);

  const modelDate = formatDate(model.updatedAt, 'dd MMMM u');

  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <header className={classes.header}>
          <h2 className={classes.mainTitle}>
            {model.commonParams.name}
          </h2>
          <div className={classes.type}>
            <p className={classes.typeDate}>
              {modelDate}
            </p>
            <Tab variant={model.stage}>
              {stageLabels[model.stage]}
            </Tab>
          </div>
        </header>
        <hr className={classes.line} />
        <SettingsBlock title="Общие параметры" className={classes.blockParams}>
          <div className={classes.block_columns}>
            {
              commonParamsOptions.map((content, index) => {
                const rootCN = cn(
                  classes.blockRow,
                  (index === 0 || index === 1) && classes.blockColSpan,
                );

                return (
                  <div
                    className={rootCN}
                    key={index}
                  >
                    <div className={classes.blockTitle}>
                      {content.title}
                    </div>
                    <div className={classes.blockSubtitle}>
                      {content.value}
                    </div>
                  </div>
                );
              })
            }
          </div>
        </SettingsBlock>
      </div>
      <SettingsBlock title="Этапы оценки">
        <div className={classes.stages}>
          {model.stages?.map((stage) => (
            <div key={stage.order} style={{ background: stage.color }} className={classes.stage}>
              {stage.name}
            </div>
          ))}
        </div>
      </SettingsBlock>
      <SettingsBlock title="Настройка отображения">
        <div className={classes.blockRow}>
          <div className={classes.blockTitle}>
            Скрыть
          </div>
          <div className={classes.blockSubtitle}>
            {model.hideFields || 'Все показывать'}
          </div>
        </div>
      </SettingsBlock>
      <SettingsBlock title="Параметры оценки">
        <div className={classes.testParamsBlockColumns}>
          {
            testOption.map((content, index) => (
              <div
                className={classes.blockRow}
                key={index}
              >
                <div className={classes.blockTitle}>
                  {content.title}
                </div>
                <div className={classes.blockSubtitle}>
                  {content.value}
                </div>
              </div>
            ))
          }
        </div>
      </SettingsBlock>
      {model.weightQuestions.length > 0 && (
        <>
          <SettingsBlock title="Количество заданий в оценке">
            {model.weightQuestions.map((question, index) => (
              <div key={`question-count-${index}`}>
                <div className={classes.questionsBlock}>
                  <div className={classes.blockRow}>
                    <div className={classes.blockTitle}>
                      Тип задания
                    </div>
                    <div className={classes.blockSubtitle}>
                      {question.type}
                    </div>
                  </div>
                  <div className={classes.blockRow}>
                    <div className={classes.blockTitle}>
                      Привязка
                    </div>
                    <div className={classes.blockSubtitle}>
                      {question.tag}
                    </div>
                  </div>
                  <div className={classes.blockRow}>
                    <div className={classes.blockTitle}>
                      Количество заданий
                    </div>
                    <div className={classes.blockSubtitle}>
                      {question.count}
                    </div>
                  </div>
                </div>
                {index !== model.weightQuestions.length - 1 && (
                  <hr className={classes.questionsLine} />
                )}
              </div>
            ))}
          </SettingsBlock>
          <SettingsBlock title="Настройка минут на каждое задание">
            {model.weightQuestions.map((question, index) => (
              <div key={`time-for-question-${index}`}>
                <div className={classes.questionsBlock}>
                  <div className={classes.blockRow}>
                    <div className={classes.blockTitle}>
                      Тип задания
                    </div>
                    <div className={classes.blockSubtitle}>
                      {question.type}
                    </div>
                  </div>
                  <div className={classes.blockRow}>
                    <div className={classes.blockTitle}>
                      Количество минут
                    </div>
                    <div className={classes.blockSubtitle}>
                      {question.time}
                    </div>
                  </div>
                </div>
                {index !== model.weightQuestions.length - 1 && (
                  <hr className={classes.questionsLine} />
                )}
              </div>
            ))}
          </SettingsBlock>
          <SettingsBlock title="Модель подсчета результатов оценки">
            <div className={classes.weightLevelsContent}>
              <Tab variant={model.stage}>Весовые коэффициенты. Тип задания</Tab>
              <div>
                {model.weightQuestions.map((question, index) => (
                  <div key={`test-result-${index}`}>
                    <div className={classes.questionsBlock}>
                      <div className={classes.blockRow}>
                        <div className={classes.blockTitle}>
                          Тип задания
                        </div>
                        <div className={classes.blockSubtitle}>
                          {question.type}
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockTitle}>
                          Вес
                        </div>
                        <div className={classes.blockSubtitle}>
                          {question.weight}
                        </div>
                      </div>
                    </div>
                    {index !== model.weightQuestions.length - 1 && (
                      <hr className={classes.questionsLine} />
                    )}
                  </div>
                ))}
              </div>
              <Tab variant={model.stage}>Весовые коэффициенты. Уровень задания</Tab>
              <div className={classes.weightLevelsBlock}>
                {model.weightLevels.map((weightLevel) => (
                  <div className={classes.blockRow} key={weightLevel.level}>
                    <div className={classes.blockTitle}>
                      {`Вес, ${levelLabels[weightLevel.level]}`}
                    </div>
                    <div className={classes.blockSubtitle}>
                      {weightLevel.weight}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <SettingsBlock title="Диапазоны" className={classes.rangeSettingBlock}>
              <div className={classes.rangeSettingContent}>
                {model.levels.map((level) => (
                  <div className={classes.blockRangesContent} key={level.type}>

                    <Tab variant="level">
                      {levelLabels[level.type]}
                    </Tab>

                    <div className={classes.blockRanges}>
                      <div className={classes.blockRow}>
                        <div className={classes.blockTitle}>
                          От
                        </div>
                        <div className={classes.blockSubtitle}>
                          {level.from}%
                        </div>
                      </div>
                      <div className={classes.blockRow}>
                        <div className={classes.blockTitle}>
                          До
                        </div>
                        <div className={classes.blockSubtitle}>
                          {level.to}%
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </SettingsBlock>
          </SettingsBlock>
        </>
      )}
      <SettingsBlock title="Отображение результатов">
        <div className={classes.blockRow}>
          <div className={classes.blockTitle}>
            Показывать
          </div>
          <div className={classes.blockSubtitle}>
            {model.displayResult}
          </div>
        </div>
      </SettingsBlock>
    </div>
  );
});
