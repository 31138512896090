import { FC, ReactElement } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { observer } from 'mobx-react';
import { WEIGHT_QUESTIONS_FIELD_NAME } from '../../../../constants/model.constants';
import { States } from '../../../../models/entities';
import { useAppStore } from '../../../../stores/context.store';
import { Input } from '../../../components/input';
import { useFormCommonStyles } from '../use-styles';
import { checkMinMaxValue, parseToNumber } from '../utils';
import { useFieldsStyles } from './use-styles';

export const QuestionsTime: FC = observer(() => {
  const commonClasses = useFormCommonStyles();
  const fieldClasses = useFieldsStyles();
  const { dictionary } = useAppStore();
  const { fields } = useFieldArray(WEIGHT_QUESTIONS_FIELD_NAME);

  return (
    <Paper className={commonClasses.paper}>
      <h3 className={commonClasses.blockTitle}>Настройка минут на каждое задание</h3>

      <div className={fieldClasses.column}>
        {fields.map((fieldName, index) => (
          <Field
            key={fieldName}
            name={fieldName}
            render={({ input: { value } }): ReactElement => (
              <>
                {value && (
                  <div key={fieldName} className={fieldClasses.flex}>
                    <Field
                      name={`${fieldName}.type`}
                      render={({ input }): ReactElement => (
                        <Input
                          value={dictionary.questionTypeLabels[input.value as string]}
                          label="Тип задания"
                          labelClassName={fieldClasses.flexGrow}
                          disabled
                        />
                      )}
                    />
                    <Field
                      name={`${fieldName}.time_for_question`}
                      validate={checkMinMaxValue({ min: 1, max: 100 })}
                      render={({ input, meta: { error, touched } }): ReactElement => (
                        <Input
                          id={`${WEIGHT_QUESTIONS_FIELD_NAME}.${index}.time_for_question`}
                          type="number"
                          value={input.value}
                          onChange={(event): void => input.onChange(parseToNumber(event.target.value))}
                          label="Количество минут"
                          labelClassName={fieldClasses.flexGrow}
                          hintText={touched && error}
                          state={(touched && error) ? States.Error : States.Default}
                        />
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        ))}
      </div>
    </Paper>
  );
});
