import { FC } from 'react';
import cn from 'classnames';
import { States } from '../../../models/entities';
import { IHintTextProps } from './hint-text.models';
import { useHintTextStyles } from './hint-text.styles';

export const HintText: FC<IHintTextProps> = ({
  hintText,
  state = States.Error,
  ...other
}) => {
  const classes = useHintTextStyles();

  const helperTextCN = cn(classes.root, {
    [classes.colorError]: state === States.Error,
    [classes.colorDefault]: state === States.Default,
    [classes.colorWarning]: state === States.Warning,
    [classes.colorSuccess]: state === States.Success,
  });

  return (
    <p className={helperTextCN} {...other}>
      {hintText}
    </p>
  );
};
