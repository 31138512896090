import { EnvKeys, IConfigStore } from '../models/stores/config.models';

export class ConfigStore implements IConfigStore {

  public readonly modelAPI: string;
  public readonly builderAPI: string;
  public readonly localToken: string | undefined;
  public readonly yandexMetrikaId: string | undefined;
  public readonly builderWEB: string;
  public readonly moderationWEB: string;
  public readonly assmnt360WEB: string;
  public readonly loggerAPI: string | undefined;
  public readonly loggerAPIKey: string | undefined;

  constructor() {
    this.modelAPI = this.getRequired(EnvKeys.modelAPI);
    this.builderAPI = this.getRequired(EnvKeys.builderAPI);
    this.localToken = this.getValue(EnvKeys.localToken);
    this.yandexMetrikaId = this.getValue(EnvKeys.yandexMetrikaId);
    this.moderationWEB = this.getRequired(EnvKeys.moderationWEB);
    this.assmnt360WEB = this.getRequired(EnvKeys.assmnt360WEB);
    this.builderWEB = this.getRequired(EnvKeys.builderWEB);
    this.loggerAPI = this.getValue(EnvKeys.loggerAPI);
    this.loggerAPIKey = this.getValue(EnvKeys.loggerAPIKey);
  }

  private getValue(key: EnvKeys): string | undefined {
    const value = process.env[key];
    return value;
  }

  private getRequired(key: EnvKeys): string {
    const value = this.getValue(key);
    if (typeof value !== 'string' || value.length === 0) {
      throw new Error(`Cannot find "${key}" parameter from environment`);
    }
    return value;
  }

}
