import { FC, SVGProps } from 'react';

export const ErrorModelIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="40" height="40" rx="8" fill="#EB5757" />
    <path
      d="M25.8337 14.1668L14.167 25.8334M25.8337 25.8334L14.167 14.1667"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
