import { FC } from 'react';
import { useFormCommonStyles } from '../../use-styles';
import { LevelWeights } from './level-weights';
import { QuestionWeights } from './question-weights';

export const WeightOptions: FC = () => {
  const commonClasses = useFormCommonStyles();

  return (
    <div>
      <h3 className={commonClasses.blockTitle}>Модель подсчета результатов оценки</h3>

      <QuestionWeights />
      <LevelWeights />
    </div>
  );
};
