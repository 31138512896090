import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { Link, generatePath, useParams } from 'react-router-dom';
import cn from 'classnames';
import { BrowserRoute } from '../../../routes/browser.routes';
import { useIntersect } from '../../../shared/use-intersect';
import { ChevronsIcon } from '../../components/icons/chevrons';
import { FolderIcon } from '../../components/icons/folder';
import { Loader } from '../../components/loader';
import { ISidebarMenuOptionProps, ISidebarMenuProps, TMenuOptionData } from './sidebar.models';
import { useSidebarMenuStyles } from './sidebar.styles';

export const Menu = <TData extends TMenuOptionData>({
  page,
  open,
  title,
  loading,
  limit,
  icon,
  stage,
  options,
  total,
  showTotal,
  setOpen,
  sendRequest,
}: ISidebarMenuProps<TData>): ReactElement => {
  const { id } = useParams();
  const [view, setView] = useState(false);
  const classes = useSidebarMenuStyles({ open });

  useEffect(() => {
    const isAllowToLoadNextPage = view && !loading && options && total > options.length;
    const isLastPage = total <= page * limit;

    if (isLastPage) {
      return;
    }

    if (isAllowToLoadNextPage) {
      sendRequest(stage, page + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  const handleOpen = (): void => {
    setOpen(stage);
  };

  const iconSize = 20;

  return (
    <div className={classes.block} key={title}
    >
      <h3 className={classes.tab} onClick={handleOpen}>
        <div className={classes.icon}>
          {icon ?? <FolderIcon />}
        </div>
        <div className={classes.title}>
          <span>{title}</span>
          <div className={classes.title_icons}>
            {showTotal && !loading && (
              <span>{total}</span>
            )}
            {loading && !open ? (
              <Loader width={iconSize} height={iconSize} />
            ) : (
              <ChevronsIcon className={classes.chevron} />
            )}
          </div>
        </div>
      </h3>

      <div className={classes.scroll_box}>
        {options?.map((option, index) => {
          const optionCN = cn(classes.option, { [classes.option_active]: id === option.id });

          return (
            <OptionLink
              key={option.id}
              id={option.id}
              label={option.label}
              isLast={options.length - 1 === index}
              className={optionCN}
              setView={(inView): void => setView(inView)}
            />
          );
        })}
        {loading && (
          <div className={classes.loading_wrapper}>
            <Loader width={iconSize} height={iconSize} />
          </div>
        )}
      </div>
    </div>
  );
};

// "OptionLink" is designed to work correctly "useIntersect"
const OptionLink: FC<ISidebarMenuOptionProps> = ({
  id,
  label,
  isLast,
  className,
  setView,
}): ReactElement => {
  const lastItemRef = useRef<HTMLDivElement>(null);
  const { inView } = useIntersect(lastItemRef);

  useEffect(() => {
    setView(inView);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <Link
      key={`sidebar_link_${id}`}
      title={label}
      className={className}
      to={generatePath(BrowserRoute.readModel, { id })}
    >
      <span ref={isLast ? lastItemRef : undefined}>
        {label}
      </span>
    </Link>
  );
};
