import { FC, MouseEventHandler, PropsWithChildren, useState } from 'react';
import { observer } from 'mobx-react';
import { Sidebar } from '../../content/sidebar';
import { TopBar } from '../topBar';
import { MIN_CONTENT_WRAPPER_WIDTH, MIN_SIDEBAR_WIDTH, useMainStyles } from './main.styles';

export const Main: FC<PropsWithChildren> = observer((props) => {
  const classes = useMainStyles();
  const MAX_SIDEBAR_WIDTH = document.documentElement.clientWidth - MIN_CONTENT_WRAPPER_WIDTH;
  const [sidebarWidth, setSidebarWidth] = useState<number>(MIN_SIDEBAR_WIDTH);
  const [isResizing, setIsResizing] = useState<boolean>(false);

  const startResizing = (): void => {
    setIsResizing(true);
  };

  const finishResizing = (): void => {
    setIsResizing(false);
  };

  const handleResize: MouseEventHandler = (event) => {
    if (!isResizing) {
      return;
    }

    const newWidth = event.clientX;

    if (newWidth < MIN_SIDEBAR_WIDTH) {
      setSidebarWidth(MIN_SIDEBAR_WIDTH);
      return;
    }

    if (newWidth > MAX_SIDEBAR_WIDTH) {
      setSidebarWidth(MAX_SIDEBAR_WIDTH);
      return;
    }

    setSidebarWidth(newWidth);
  };

  return (
    <main className={classes.main} onMouseMove={handleResize} onMouseUp={finishResizing}>
      <div className={classes.wrapper}>
        <Sidebar sidebarWidth={sidebarWidth} startResizing={startResizing} />
        <div className={classes.page}>
          <TopBar />
          <div className={classes.content}>
            {props.children}
          </div>
        </div>
      </div>
    </main>
  );
});
