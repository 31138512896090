import { FC } from 'react';
import cn from 'classnames';
import _uniqueId from 'lodash/uniqueId';
import { States } from '../../../models/entities';
import { HintText } from '../hint-text';
import { IInputProps } from './input.models';
import { useInputStyles } from './input.styles';

export const Input: FC<IInputProps> = ({
  label,
  className,
  hintText,
  state = States.Default,
  icon,
  iconPosition = 'right',
  labelClassName,
  id = _uniqueId('prefix-'),
  ...other
}) => {
  const classes = useInputStyles();

  const inputCN = cn(
    classes.input,
    {
      [classes.colorDefault]: state === States.Default,
      [classes.colorError]: state === States.Error,
      [classes.colorWarning]: state === States.Warning,
      [classes.colorSuccess]: state === States.Success,
    },
    {
      [classes.input_right]: iconPosition === 'right',
      [classes.input_left]: iconPosition === 'left',
    },
    className,
  );

  const inputIconCN = cn(
    {
      [classes.icon_active]: !!iconPosition,
      [classes.icon_inactive]: !iconPosition,
      [classes.icon_right]: iconPosition === 'right',
      [classes.icon_left]: iconPosition === 'left',
    },
  );

  const labelCN = cn(classes.root, labelClassName);

  return (
    <label htmlFor={id} className={labelCN}>
      {label && (
        <p className={classes.label_text}>
          {label}
        </p>
      )}
      <div className={classes.inputWrapper}>
        <input
          className={inputCN}
          {...other}
          id={id}
        />
        {icon && (
          <div className={inputIconCN}>
            {icon}
          </div>
        )}
      </div>

      <HintText hintText={hintText} state={state} />
    </label>
  );
};
