import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';
import { BrowserRoute } from '../../../routes/browser.routes';
import { Button } from '../button';
import { CloseIcon } from '../icons/close';
import { IActionsButtonsProps } from './actions.models';
import { useActionsStyles } from './actions.styles';

const DEFAULT_ICON_SIZE = 20;

export const ActionsButtons: FC<IActionsButtonsProps> = ({
  iconSize = DEFAULT_ICON_SIZE,
  options,
  className,
  closeAction,
  removeCloseAction,
  ...props
}) => {
  const navigate = useNavigate();

  const classes = useActionsStyles();
  const blockCN = cn(
    classes.root,
    classes.posSticky,
    className,
  );

  const closeActionBtn = !removeCloseAction
    ? closeAction ?? {
      label: 'Закрыть',
      value: undefined,
      icon: <CloseIcon width={iconSize} height={iconSize} />,
      onClick: () => navigate(BrowserRoute.index),
    }
    : {};

  const actions = [
    closeActionBtn,
    ...options,
  ];

  return (
    <div className={blockCN} {...props}>
      {actions.map(({ label, icon, ...buttonProps }, index) => (
        <Button
          className={classes.button}
          variant="white"
          size="sm"
          {...buttonProps}
          key={index}
        >
          {icon}
          <span>{label}</span>
        </Button>
      ))}
    </div>
  );
};
