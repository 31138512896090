import { FC } from 'react';
import cn from 'classnames';
import { TrashIcon } from '../icons/trash';
import { IColorPickerProps } from './color-picker.models';
import { useColorPickerStyles } from './color-picker.styles';

export const ColorPicker: FC<IColorPickerProps> = (props) => {
  const classes = useColorPickerStyles();
  const { onChange, label, colors, value, hideRemoveBtn = false, onRemoveStage } = props;

  const handleClick = (color: string): void => {
    onChange(color);
  };

  return (
    <div className={classes.root}>
      <p className={classes.label_text}>
        {label}
      </p>
      <div className={classes.colors}>
        {colors.map((color) => (
          <div
            key={color}
            onClick={(): void => handleClick(color)}
            className={cn(classes.colorWrap, {
              [classes.active]: value === color,
            })}
          >
            <div
              style={{ background: color }}
              className={classes.color}
            />
          </div>
        ))}
        {!hideRemoveBtn && (
          <TrashIcon className={classes.delete} onClick={onRemoveStage} />
        )}
      </div>
    </div>
  );
};
