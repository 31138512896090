import { createUseStyles } from 'react-jss';

export const useStagesStyles = createUseStyles(
  (theme) => ({
    stages: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 24,
    },
    btn: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      marginLeft: 'auto !important',
    },
    btnDisabled: {
      backgroundColor: 'transparent!important',
      color: `${theme.palette.disabled.main}!important`,
    },
  }),
  { name: 'Stages' },
);

export const useFieldsStyles = createUseStyles(
  (theme) => ({
    fields: {
      display: 'grid',
      alignItems: 'flex-start',
      columnGap: 28,
      rowGap: 16,
      gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    },
    field: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: ['10px', '16px'],
      borderRadius: 12,
      backgroundColor: theme.palette.hover.main,
      cursor: 'pointer',
    },
    flex: {
      display: 'flex',
      gap: 28,
    },
    flexGrow: {
      flexGrow: 1,
    },
    fullWidth: {
      width: '100%',
    },
    marginBottom24: {
      marginBottom: 24,
    },
    label: {
      fontSize: 14,
      color: theme.palette.secondaryText.main,
      marginBottom: 14,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
    ranges: {
      display: 'grid',
      alignItems: 'flex-start',
      columnGap: 28,
      rowGap: 16,
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    },
    registrationType: {
      width: 120,
    },
    showQuestions: {
      flexDirection: 'row',
    },
    certificate: {
      gap: 8,
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '14px',
      alignItems: 'center',
      padding: 10,
      cursor: 'pointer',
      color: theme.palette.gloomyBlue.main,
      width: 275,
    },
  }),
  { name: 'FieldsDisplay' },
);

export const useToolbarStyles = createUseStyles(
  (theme) => ({
    actions: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: {
        top: 28,
        bottom: 28,
        left: 8,
        right: 8,
      },
      height: 'min-content',
      position: 'sticky',
      zIndex: 50,
      top: 120,
      width: 112,
    },
    button: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: 8,
      fontSize: 12,
      color: theme.palette.secondaryText.main,

      '&:hover': {
        color: theme.palette.black.main,
      },

      '&:disabled': {
        color: theme.palette.disabled.main,
      },
    },
  }),
  { name: 'FormToolbar' },
);
