import { FC, useContext } from 'react';
import cn from 'classnames';
import { appStoreContext } from '../../../stores/context.store';
import { LeftArrowIcon } from '../icons/left-arrow';
import { useTopBarStyles } from './topBar.styles';

export const TopBar: FC = () => {
  const classes = useTopBarStyles();
  const appStore = useContext(appStoreContext);

  const linkCN = cn(classes.link, classes.back_link);

  return (
    <div className={classes.root}>
      <hr className={classes.line} />
      <div className={classes.content}>
        <a href={appStore.config.builderWEB} className={linkCN}>
          <LeftArrowIcon />
          <span>
            Вернуться к компетенциям и заданиям
          </span>
        </a>
        <a href={appStore.config.moderationWEB} className={classes.link}>
          <span>
            Модерация
          </span>
        </a>
        <div className={classes.link_active}>
          Модели оценочных сессий
        </div>
        <a href={appStore.config.assmnt360WEB} className={linkCN}>
          Оценка 360
        </a>
      </div>
    </div>
  );
};
