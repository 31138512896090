import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ModelStage } from '@unione-pro/unione.assmnt.sdk.webapp';
import { useUnmount } from 'ahooks';
import { observer } from 'mobx-react';
import { BrowserRoute } from '../../../routes/browser.routes';
import { appStoreContext } from '../../../stores/context.store';
import { ActionsButtons, IActionsButtonsOption } from '../../components/actions';
import { DuplicateIcon } from '../../components/icons/duplicate';
import { TrashIcon } from '../../components/icons/trash';
import { LoaderPage } from '../../components/loader';
import { WentWrong } from '../went-wrong';
import { RemoveModal } from './components';
import { RemoveModalType } from './model-readonly.model';
import { useModelReadonlyWrapperStyles } from './model-readonly.styles';
import { ModelReadonly } from './model-readonly.view';

export const ModelReadonlyWrapper: FC = observer(() => {
  const classes = useModelReadonlyWrapperStyles();
  const navigate = useNavigate();
  const { id } = useParams();

  const appStore = useContext(appStoreContext);
  const { model, loading, getModel, reset, removeModel } = appStore.modelDetail;
  const { data: dictionaries, loading: dictionariesLoading } = appStore.dictionary;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType] = useState(RemoveModalType.confirm);

  const closeModal = (): void => {
    setModalIsOpen(false);
  };

  const openModal = (): void => {
    setModalIsOpen(true);
    setModalType(RemoveModalType.confirm);
  };

  const handleRemoveModel = async(): Promise<void> => {
    if (!id) {
      return;
    }
    try {
      await removeModel(id);
      navigate(BrowserRoute.index);
    }
    catch (error) {
      setModalType(RemoveModalType.info);
    }
  };

  useEffect(() => {
    if (id) {
      getModel(id);
    }
  }, [id, getModel]);

  useUnmount(reset);

  const actions = useMemo<IActionsButtonsOption[]>(() => {
    const list: IActionsButtonsOption[] = [];
    const drafted = model?.stage === ModelStage.draft;
    const modelId = model?._id;

    if (modelId) {
      const duplicate = {
        label: 'Создать копию',
        icon: <DuplicateIcon width={20} height={20} />,
        onClick: () => navigate(generatePath(BrowserRoute.duplicatedModel, { id: modelId })),
      };

      list.push(duplicate);

      if (drafted) {
        const edit = {
          label: 'Редактировать',
          icon: <DuplicateIcon width={20} height={20} />,
          onClick: () => navigate(generatePath(BrowserRoute.updateModel, { id: modelId })),
        };

        list.push(edit);
      }

      const remove = {
        label: 'Удалить',
        icon: <TrashIcon width={20} height={20} />,
        onClick: openModal,
      };

      list.push(remove);
    }

    return list;
  }, [model?._id, model?.stage, navigate]);

  if (loading || dictionariesLoading) {
    return <LoaderPage />;
  }

  if (!id) {
    return <WentWrong />;
  }

  if (!model || !dictionaries) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.page}>
        <ModelReadonly model={model} />
        <ActionsButtons className={classes.actions} options={actions} />
      </div>
      <RemoveModal isOpen={modalIsOpen} onCancel={closeModal} type={modalType} onOk={handleRemoveModel} />
    </div>
  );
});
