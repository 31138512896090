import { createUseStyles } from 'react-jss';
import { IRadioInputStylesProps } from './radio-block.models';

const DEFAULT_SIZE = 16;
const INNER_DEFAULT_SIZE = 10;

const getInnerPadding = (props?: IRadioInputStylesProps): string => {
  if (!props) {
    return `${((DEFAULT_SIZE - INNER_DEFAULT_SIZE - 2) / 2)}px`;
  }

  const outerSize = props?.size || DEFAULT_SIZE;
  const innerSize = props?.innerSize || INNER_DEFAULT_SIZE;

  return `${(outerSize - innerSize - 2) / 2}px`;
};

export const useRadioInputStyles = createUseStyles<'root', IRadioInputStylesProps, Jss.Theme>(
  (theme) => ({
    root: {
      whiteSpace: 'nowrap',
      outline: 'none',
      position: 'relative',
      width: (props): string => `${props?.size ?? DEFAULT_SIZE}px`,
      height: (props): string => `${props?.size ?? DEFAULT_SIZE}px`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '&:hover .rc-checkbox-inner': {
        borderColor: '#bcbcbc',
      },

      '& .rc-checkbox-inner': {
        position: 'relative',
        top: '0',
        left: '0',
        display: 'inline-block',
        width: (props): string => `${props?.size ?? DEFAULT_SIZE}px`,
        height: (props): string => `${props?.size ?? DEFAULT_SIZE}px`,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '100%',
        borderColor: '#d9d9d9',
        backgroundColor: theme.background.background,
        transition: 'border-color 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), background-color 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)',

        '&:after': {
          position: 'absolute',
          width: (props): string => `${props?.innerSize ?? INNER_DEFAULT_SIZE}px`,
          height: (props): string => `${props?.innerSize ?? INNER_DEFAULT_SIZE}px`,
          left: (props): string => getInnerPadding(props),
          top: (props): string => getInnerPadding(props),
          borderRadius: '6px',
          display: 'table',
          borderTop: '0',
          borderLeft: '0',
          content: "' '",
          backgroundColor: theme.palette.primary.main,
          transform: 'scale(0)',
          webkitTransform: 'scale(0)',
          opacity: '0',
          transition: 'transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), background-color 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
          webkitTransition: '-webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), background-color 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        },
      },

      '& .rc-checkbox-input': {
        position: 'absolute',
        left: '0',
        zIndex: 6,
        cursor: 'pointer',
        opacity: '0',
        top: '-2px',
        bottom: '0',
        right: '0',
      },

      '&.rc-checkbox-checked .rc-checkbox-inner': {
        borderColor: '#d9d9d9',

        '&:after': {
          transform: 'scale(1)',
          webkitTransform: 'scale(1)',
          opacity: '1',
          transition: 'transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), background-color 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
          webkitTransition: '-webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28), opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86), background-color 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86)',
        },
      },

      '&.rc-checkbox-disabled:hover .rc-checkbox-inner': {
        borderColor: '#d9d9d9',
      },

      '&.rc-checkbox-disabled .rc-checkbox-inner': {
        borderColor: '#d9d9d9',
        backgroundColor: '#f3f3f3',
      },

      '&.rc-checkbox-disabled .rc-checkbox-inner:after': {
        backgroundColor: '#cccccc',
      },

      '&.rc-checkbox-disabled .rc-checkbox-inner-input': {
        cursor: 'default',
      },
    },
  }),
);
