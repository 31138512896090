import { createUseStyles } from 'react-jss';

export const useRadioBlockStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    marginBottom: '14px',
    color: theme.palette.secondaryText.main,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '14px',
    lineHeight: '20px',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    gap: '24px',
  },
  option: {
    display: 'flex',
    columnGap: '8px',
    alignItems: 'center',
    justifyContent: 'start',
    cursor: 'pointer',
    width: 'fit-content',
  },
  label: {
    color: theme.palette.gloomyBlue.main,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '16px',
    lineHeight: '20px',
  },
  input: {
    outline: 'none',
  },
}));
