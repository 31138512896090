import { FC, ReactElement } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import cn from 'classnames';
import { WEIGHT_QUESTIONS_FIELD_NAME } from '../../../../../constants/model.constants';
import { States } from '../../../../../models/entities';
import { useAppStore } from '../../../../../stores/context.store';
import { Input } from '../../../../components/input';
import { checkMinMaxValue, parseToNumber } from '../../utils';
import { useFieldsStyles } from '../use-styles';

export const QuestionWeights: FC = () => {
  const fieldClasses = useFieldsStyles();
  const { dictionary } = useAppStore();
  const { fields } = useFieldArray(WEIGHT_QUESTIONS_FIELD_NAME);

  return (
    <div className={cn(fieldClasses.column, fieldClasses.marginBottom24)}>
      <Chip label="Весовые коэффициенты. Тип задания" color="primary" />
      <div className={fieldClasses.column}>
        {fields.map((fieldName, index) => (
          <Field
            key={fieldName}
            name={fieldName}
            render={({ input: { value } }): ReactElement => (
              <>
                {value && (
                  <div className={fieldClasses.flex}>
                    <Field
                      name={`${fieldName}.type`}
                      render={({ input }): ReactElement => (
                        <Input
                          value={dictionary.questionTypeLabels[input.value as string]}
                          label="Тип задания"
                          labelClassName={fieldClasses.flexGrow}
                          disabled
                        />
                      )}
                    />
                    <Field
                      name={`${fieldName}.weight`}
                      validate={checkMinMaxValue({ min: 1, max: 10 })}
                      render={({ input, meta: { error, touched } }): ReactElement => (
                        <Input
                          id={`${WEIGHT_QUESTIONS_FIELD_NAME}.${index}.weight`}
                          type="number"
                          value={input.value}
                          onChange={(event): void => input.onChange(parseToNumber(event.target.value))}
                          label="Вес"
                          labelClassName={fieldClasses.flexGrow}
                          hintText={touched && error}
                          state={(touched && error) ? States.Error : States.Default}
                        />
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        ))}
      </div>
    </div>
  );
};
