import { createUseStyles } from 'react-jss';

export const useRemoveModelModalStyles = createUseStyles(
  (theme) => ({
    root: {
      padding: '32px !important',
      rowGap: '0 !important',
    },
    closeIcon: {
      marginLeft: 'auto',
      color: theme.palette.gray.contrastText,
      cursor: 'pointer',
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 32,
      padding: 8,
      textAlign: 'center',
    },
    modelIcon: {
      margin: '0 auto',
    },
    title: {
      fontFamily: theme.fontFamily.monserrat,
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '24px',
    },
    actions: {
      display: 'flex',
      gap: 12,
      paddingTop: 32,
    },
    actionBtn: {
      padding: '12px 24px !important',
      flexBasis: '50%',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '20px',
    },
  }),
  { name: 'modal' },
);
