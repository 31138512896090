import { FC } from 'react';
import cn from 'classnames';
import { IButtonProps } from './button.models';
import { useButtonStyles } from './button.styles';

export const Button: FC<IButtonProps> = ({
  variant = 'primary',
  variantStyle,
  size = 'md',
  className,
  disabled,
  ...other
}) => {
  const classes = useButtonStyles();

  const rootCN = cn(
    classes.root,
    {
      [classes.variantStandard]: variantStyle === 'standard',
      [classes.variantOutlined]: variant === 'outline',
      [classes.colorPrimary]: variant === 'primary',
      [classes.colorWarning]: variant === 'warning',
      [classes.colorError]: variant === 'danger',
      [classes.colorGray]: variant === 'gray',
      [classes.colorPrimaryGradient]: variant === 'gradient',
      [classes.colorSuccess]: variant === 'success',
      [classes.colorWhite]: variant === 'white',
      [classes.sizeNone]: size === 'none',
      [classes.sizeMedium]: size === 'md',
      [classes.sizeSmall]: size === 'sm',
      [classes.disabled]: disabled,
    },
    className,
  );

  return <button {...other} disabled={disabled} className={rootCN} />;
};
