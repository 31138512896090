import { createUseStyles } from 'react-jss';

export const useSelectStyles = createUseStyles((theme) => ({
  root: {
    width: '100%',
    position: 'relative',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.palette.lines.main,
    borderRadius: '4px',
  },

  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none',
  },

  selected_label: {
    color: theme.palette.gloomyBlue.main,
    outline: '2px solid transparent',
    outlineOffset: '2px',
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    appearance: 'none',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
  },

  selected_label_disabled: {
    backgroundColor: theme.palette.lines.main,
  },

  selected_label_error: {
    border: '1px solid',
    borderColor: theme.palette.error.main,
    borderRadius: '0.25rem',
  },

  selected_label_sm: {
    paddingLeft: '0.25rem',
    paddingRight: '0.25rem',
  },

  selected_label_md: {
    paddingLeft: '1rem',
    paddingRight: '1rem',
  },

  dropdown: {
    backgroundColor: theme.background.paper,
    borderRadius: '0.25rem',
    overflow: 'hidden',
    minWidth: '100%',
    zIndex: 6,
    position: 'absolute',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.05)',
  },

  dropTop: {
    bottom: 'calc(100% + 10px)',
  },

  dropBottom: {
    top: 'calc(100% + 10px)',
  },

  dropLeft: {
    left: 0,
  },

  dropRight: {
    right: 0,
  },

  visible: {
    visibility: 'visible',
  },

  invisible: {
    visibility: 'hidden',
  },

  dropdown__content: {
    overflowY: 'auto',
    scrollPaddingBottom: '0.5rem',
    scrollPaddingTop: '0.5rem',
    maxHeight: '300px',
  },

  option: {
    color: theme.palette.secondaryText.main,
    paddingBottom: '0.5rem',
    paddingTop: '0.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.hover.main,
    },
  },

  option_active: {
    color: theme.palette.primary.main,
  },

  icon: {
    cursor: 'pointer',
    transitionDuration: '0.15s',
    color: theme.palette.gray.contrastText,
  },

  icon_md: {
    right: '1rem',
  },

  icon_sm: {
    right: '0.25rem',
  },

  icon_rotate_180: {
    transform: 'rotateZ(180deg)',
  },

  error_text: {
    color: theme.palette.error.main,
    fontSize: '1rem',
    fontWeight: theme.typography.fontWeightLight,
    lineHeight: '1.25rem',
  },

  label: {
    color: theme.palette.secondaryText.main,
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '14px',
    lineHeight: '20px',
    marginBottom: '4px',
  },

  wrapper_withShadow: {},

}));
