import { FC, SVGProps } from 'react';

export const DoneIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 10.7778L8.37362 12.1768C8.80011 12.6112 9.51249 12.567 9.88195 12.0832L13 8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <circle cx="10" cy="10" r="7.25" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);
