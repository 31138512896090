import { FC, ReactElement } from 'react';
import { Field } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { ICourseModelLevel } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Chip } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/chip';
import { observer } from 'mobx-react';
import { CHIP_CUSTOM_COLOR, LEVELS_FIELD_NAME } from '../../../../constants/model.constants';
import { States } from '../../../../models/entities';
import { useAppStore } from '../../../../stores/context.store';
import { Input } from '../../../components/input';
import { useFormCommonStyles } from '../use-styles';
import { levelRangesValidate, parseToNumber } from '../utils';
import { useFieldsStyles } from './use-styles';

export const LevelRanges: FC = observer(() => {
  const fieldClasses = useFieldsStyles();
  const commonClasses = useFormCommonStyles();
  const { dictionary } = useAppStore();
  const { fields } = useFieldArray(LEVELS_FIELD_NAME, { validate: levelRangesValidate });

  const values: ICourseModelLevel[] = fields.value;

  const getLabel = (index: number): string => {
    const level = values[index].type;
    return dictionary.rangeLabels[level];
  };

  const lastIndex = values.length - 1;

  return (
    <div>
      <h3 className={commonClasses.blockTitle}>Диапазоны</h3>

      <div className={fieldClasses.ranges}>
        {fields.map((fieldName, index) => (
          <div key={fieldName} className={fieldClasses.column}>
            <Chip label={getLabel(index)} customColor={CHIP_CUSTOM_COLOR} />
            <div className={fieldClasses.flex} id={`${LEVELS_FIELD_NAME}.${index}`}>
              <Field
                name={`${fieldName}.from`}
                render={({ input: { value, onChange }, meta }): ReactElement => (
                  <Input
                    type="number"
                    value={value}
                    onChange={(event): void => onChange(parseToNumber(event.target.value))}
                    label="От"
                    icon={<>%</>}
                    labelClassName={fieldClasses.fullWidth}
                    disabled={index === 0}
                    hintText={index !== 0 && meta.error}
                    state={index !== 0 && meta.error ? States.Error : States.Default}
                  />
                )}
              />

              <Field
                name={`${fieldName}.to`}
                render={({ input: { value, onChange }, meta }): ReactElement => (
                  <Input
                    type="number"
                    value={value}
                    onChange={(event): void => onChange(parseToNumber(event.target.value))}
                    label="До"
                    icon={<>%</>}
                    labelClassName={fieldClasses.fullWidth}
                    disabled={index === lastIndex}
                    hintText={index !== lastIndex && meta.error}
                    state={index !== lastIndex && meta.error ? States.Error : States.Default}
                  />
                )}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
});
