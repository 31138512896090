import { FC, Fragment, useContext, useEffect, PropsWithChildren } from 'react';
import { observer } from 'mobx-react';
import { appStoreContext } from '../../../stores/context.store';
import { LoaderPage } from '../../components/loader';
import { WentWrong } from '../went-wrong';

export const Dictionary: FC<PropsWithChildren> = observer((props) => {
  const appStore = useContext(appStoreContext);
  const { data, loading } = appStore.dictionary;

  useEffect(() => {
    appStore.dictionary.getDictionary();
  }, []);

  const iconSize = 48;

  if (loading) {
    return (
      <LoaderPage width={iconSize} height={iconSize}>
        <span>Выполняется загрузка справочника...</span>
      </LoaderPage>
    );
  }

  if (!data) {
    return (
      <WentWrong
        title="Что-то пошло не так..."
        subtitle="Попробуйте перезагрузить страницу и проверьте подключение к интернету."
      />
    );
  }

  return (
    <Fragment>
      {props.children}
    </Fragment>
  );
});
