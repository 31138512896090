export const COURSE_STAGE_COLORS = [
  '#E26C3C',
  '#F39A9A',
  '#F7B055',
  '#7D6FE1',
  '#73AAFF',
  '#99C6EB',
  '#0071CE',
  '#07B4CF',
  '#7BE7C7',
  '#CAD753',
];

export const DEFAULT_STAGE_COLOR = {
  name: '',
  color: COURSE_STAGE_COLORS[0],
};
