import { FC, SVGProps } from 'react';

export const WarningModelIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="64" height="64" rx="12" fill="#FFC700" />
    <path
      d="M32.0004 31.9999V27.1999M32.0004 35.5577V35.5999M41.6004 31.9999C41.6004 37.3018 37.3023 41.5999 32.0004 41.5999C26.6985 41.5999 22.4004 37.3018 22.4004 31.9999C22.4004 26.698 26.6985 22.3999 32.0004 22.3999C37.3023 22.3999 41.6004 26.698 41.6004 31.9999Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
