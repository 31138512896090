import { sdkGetDictionary } from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, runInAction } from 'mobx';
import {
  DictionaryData,
  IDictionaryStore,
  PreparedLevels,
} from '../models/stores/dictionary.store';
import { IRootStore } from '../models/stores/root.store';
import { getErrorMessage } from '../shared/error-message';
import { generateLabelByValue } from './utils';

export class DictionaryStore implements IDictionaryStore {

  public readonly rootStore: IRootStore;

  public data?: DictionaryData;
  public loading: boolean = true;
  public error?: string;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }

  public async getDictionary(): Promise<void> {
    try {
      runInAction(() => {
        this.loading = true;
        this.data = undefined;
        this.error = undefined;
      });

      const response = await sdkGetDictionary({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
      });

      const levels = response.testLevels.filter((level) => level.value !== 'basic_level');

      runInAction(() => {
        this.data = {
          ...response,
          levels,
        };
        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  }

  get questionTypeLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.questionsTypes);
  }

  get levelLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.testLevels);
  }

  get rangeLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.testLevels);
  }

  get testTypeLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.testTypes);
  }

  get questionOutputLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.questionOutputs);
  }

  get testTagsLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.testTags);
  }

  get displayResultLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.displayResults);
  }

  get stageLabels(): PreparedLevels {
    return generateLabelByValue(this.data?.modelStages);
  }

}
