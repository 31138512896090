import { generatePath } from 'react-router-dom';
import {
  DisplayResults,
  ICourseModel,
  ModelLevel,
  ModelStage,
  sdkCreateModel,
  sdkGetModel,
  sdkPublishModel,
  sdkUpdateModel,
} from '@unione-pro/unione.assmnt.sdk.webapp';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { DEFAULT_STAGE_COLOR } from '../constants/course-stages.constants';
import { LEVEL_WEIGHT_BY_TEST_LEVEL } from '../constants/model.constants';
import { ResponseMappers } from '../mappers/response.mappers';
import { IModelFormStore } from '../models/stores/model-form.store';
import { IRootStore } from '../models/stores/root.store';
import { BrowserRoute } from '../routes/browser.routes';
import { getErrorMessage } from '../shared/error-message';
import { toast } from '../views/components/notification';
import { generateDefaultLevel } from './utils';

export class ModelFormStore implements IModelFormStore {

  public readonly rootStore: IRootStore;

  public data?: ICourseModel;
  public loading: boolean = false;
  public error?: string;
  public redirectPath?: string;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });

    reaction(
      () => this.error,
      (text) => {
        text && toast({ text, type: 'error' });
        this.error = undefined;
      },
    );
  }

  public reset = (): void => {
    this.data = undefined;
    this.error = undefined;
    this.redirectPath = undefined;
    this.loading = false;
  };

  public getModel = async(id: string): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
        this.data = undefined;
        this.error = undefined;
      });

      const response = await sdkGetModel({
        baseURL: this.rootStore.config.modelAPI,
        token: this.rootStore.user.token,
        data: { id },
      });

      const testOptions = this.rootStore.dictionary.data;

      if (!testOptions) {
        return;
      }

      const testType = response.test_options.test_type;

      const test_options = {
        ...response.test_options,
        weight_questions: ResponseMappers.weightQuestionsMapper({
          testType,
          questionsTypes: testOptions.questionsTypes,
          currentWeightQuestions: response.test_options.weight_questions,
        }),
      };

      runInAction(() => {
        this.data = {
          ...response,
          test_options,
        };
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
    finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  };

  public formSubmit = async(model: ICourseModel): Promise<void> => {
    try {
      runInAction(() => {
        this.loading = true;
        this.error = undefined;
      });
      const { stages, stage } = model;

      const preparedData: ICourseModel = {
        ...model,
        common_params: {
          ...model.common_params,
          count_of_stages: stages.length,
        },
        stage: ModelStage.draft,
        stages: stages.map((stg, idx) => ({ ...stg, order: idx + 1 })),
      };

      let id = model._id;

      if (!id) {
        id = await this.createModel(preparedData);
      }
      else {
        await this.updateModel(preparedData);
      }

      if (stage === ModelStage.published) {
        await this.publicateModel(id);
      }

      runInAction(() => {
        if (id) {
          this.redirectPath = generatePath(BrowserRoute.readModel, { id });
        }

        this.loading = false;
      });
    }
    catch (error) {
      runInAction(() => {
        this.error = getErrorMessage(error);
        this.loading = false;
      });
    }
  };

  public createModel = async(data: ICourseModel): Promise<string> => {
    const response = await sdkCreateModel({
      baseURL: this.rootStore.config.modelAPI,
      token: this.rootStore.user.token,
      data,
    });

    if (data.stage === ModelStage.draft) {
      this.rootStore.modelSidebar.resetDraftAndRequest();
    }

    return response._id;
  };

  public async updateModel(data: ICourseModel): Promise<void> {
    await sdkUpdateModel({
      baseURL: this.rootStore.config.modelAPI,
      token: this.rootStore.user.token,
      data,
    });

    if (data.stage === ModelStage.draft) {
      this.rootStore.modelSidebar.resetDraftAndRequest();
    }
  }

  public async publicateModel(id: string): Promise<void> {
    await sdkPublishModel({
      baseURL: this.rootStore.config.modelAPI,
      token: this.rootStore.user.token,
      data: { id },
    });

    this.rootStore.modelSidebar.resetDraftAndRequest();
    this.rootStore.modelSidebar.resetPublishedAndRequest();
  }

  get initialValues(): ICourseModel | {} {
    if (this.data) {
      const {
        created_at: _created_at,
        updated_at: _updated_at,
        ...data
      } = this.data;
      return data;
    }

    const testOptions = this.rootStore.dictionary.data;

    if (!testOptions) {
      return {};
    }

    const [defaultRegistrationType] = testOptions.registrationTypes;
    const [defaultTestType] = testOptions.testTypes;
    const [defaultQuestionOutput] = testOptions.questionOutputs;

    const weightQuestions = ResponseMappers.weightQuestionsMapper({
      testType: defaultTestType.value,
      questionsTypes: testOptions.questionsTypes,
    });

    const weightLevels = testOptions.levels.map(({ value }) => ({
      level: value,
      weight: LEVEL_WEIGHT_BY_TEST_LEVEL[value as ModelLevel],
    }));

    const levels = testOptions.testLevels.map(generateDefaultLevel);

    return {
      common_params: {
        name: '',
        level: ModelLevel.expertLevel,
        count_of_attempts: 2,
        max_count_of_testers: 10000,
        registration_type: defaultRegistrationType.value,
        certificates: false,
      },
      stages: [DEFAULT_STAGE_COLOR],
      fields_display: {
        show_academic_hours: true,
        show_academic_months: true,
        show_branch_industry: true,
        show_description: true,
        show_docs: true,
        show_it: true,
        show_qualification_name: true,
        show_questions: true,
        show_year: true,
      },
      test_options: {
        test_type: defaultTestType.value,
        output: defaultQuestionOutput.value,
        weight_questions: weightQuestions,
        weight_levels: weightLevels,
      },
      levels,
      display_results: DisplayResults.competency,
      stage: ModelStage.draft,
    };
  }

}
