import { FC, SVGProps } from 'react';
import { useLoaderStyles } from './loader.styles';
import { Loader } from './loader.view';

export const LoaderPage: FC<SVGProps<SVGSVGElement>> = ({ children, ...other }) => {
  const classes = useLoaderStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.block}>
        <Loader {...other} />
        {children}
      </div>
    </div>
  );
};
