import { ITestOption, TestType } from '@unione-pro/unione.assmnt.sdk.webapp';
import {
  QUESTION_TAG_SELECT_DISABLED,
  QUESTION_WEIGHT_BY_QUESTION_TYPE,
  TAG_BY_TEST_TYPE,
  TIME_FOR_QUESTION_BY_QUESTION_TYPE,
} from '../constants/model.constants';
import { DictionaryData } from '../models/stores/dictionary.store';

type WeightQuestionsMapperArgs = {
  testType: TestType;
  questionsTypes: DictionaryData['questionsTypes'];
  currentWeightQuestions?: ITestOption[];
};

export class ResponseMappers {

  public static weightQuestionsMapper = ({
    testType,
    questionsTypes,
    currentWeightQuestions,
  }: WeightQuestionsMapperArgs): ITestOption[] => {
    const defaultTagsByTestType = TAG_BY_TEST_TYPE[testType];

    return questionsTypes.map(({ value }) => {
      const foundWeightQuestion = currentWeightQuestions?.find(
        ({ type }) => type === value,
      );

      if (!foundWeightQuestion) {
        return {
          type: value,
          tag: defaultTagsByTestType[value],
          count: 2,
          weight: QUESTION_WEIGHT_BY_QUESTION_TYPE[value],
          is_enabled: !currentWeightQuestions,
          time_for_question: TIME_FOR_QUESTION_BY_QUESTION_TYPE[value],
        };
      }

      const { type, tag } = foundWeightQuestion;

      const isDisabled = QUESTION_TAG_SELECT_DISABLED[type];
      const defaultTag = defaultTagsByTestType[type];

      if (isDisabled && tag !== defaultTag) {
        foundWeightQuestion.tag = defaultTag;
      }

      return foundWeightQuestion;
    });
  };

}
