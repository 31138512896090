import { FC, ReactElement, useMemo } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useFieldArray } from 'react-final-form-arrays';
import { ITestOption, TestTag } from '@unione-pro/unione.assmnt.sdk.webapp';
import { Paper } from '@unione-pro/unione.assmnt.ui-kit.webapp/lib/paper';
import { observer } from 'mobx-react';
import { QUESTION_TAG_SELECT_DISABLED, WEIGHT_QUESTIONS_FIELD_NAME } from '../../../../constants/model.constants';
import { States } from '../../../../models/entities';
import { useAppStore } from '../../../../stores/context.store';
import { Input } from '../../../components/input';
import { Select } from '../../../components/select';
import { useFormCommonStyles } from '../use-styles';
import { checkMinMaxValue, parseToNumber } from '../utils';
import { useFieldsStyles } from './use-styles';

export const QuestionsCount: FC = observer(() => {
  const commonClasses = useFormCommonStyles();
  const fieldClasses = useFieldsStyles();
  const { dictionary } = useAppStore();
  const { fields } = useFieldArray(WEIGHT_QUESTIONS_FIELD_NAME);
  const { values } = useFormState();
  const form = useForm();

  const { test_type } = values.test_options;

  const { data, questionTypeLabels } = dictionary;

  const testTags = useMemo(() => {
    if (!data?.testTags) {
      return [];
    }

    return data.testTags.filter(({ value }) => value === test_type || value === TestTag.competency_level);
  }, [test_type, data]);

  const isDisabledField = (index: number, hasTagField: boolean = false): boolean => {
    const { is_enabled, type, count } = fields.value[index] as ITestOption;

    if (!is_enabled && count) {
      form.change(`${fields.name}[${index}].count`, 0);
    }

    if (hasTagField) {
      return !is_enabled || QUESTION_TAG_SELECT_DISABLED[type];
    }

    return !is_enabled;
  };

  return (
    <Paper className={commonClasses.paper}>
      <h3 className={commonClasses.blockTitle}>Количество заданий в оценке</h3>

      <div className={fieldClasses.column}>
        {fields.map((fieldName, index) => (
          <Field
            key={fieldName}
            name={fieldName}
            render={({ input: { value } }): ReactElement => (
              <>
                {value && (
                  <div key={fieldName} className={fieldClasses.fields}>
                    <Field
                      name={`${fieldName}.type`}
                      render={({ input }): ReactElement => (
                        <Input
                          value={questionTypeLabels[input.value as string]}
                          label="Тип задания"
                          disabled
                        />
                      )}
                    />
                    <Field
                      name={`${fieldName}.tag`}
                      render={({ input }): ReactElement => (
                        <Select
                          label="Привязка"
                          value={testTags.find((tag) => tag.value === input.value)}
                          onChange={input.onChange}
                          options={testTags}
                          disabled={isDisabledField(index, true)}
                        />
                      )}
                    />
                    <Field
                      name={`${fieldName}.count`}
                      validate={checkMinMaxValue({ min: 0, max: 1000 })}
                      render={({ input, meta: { error, touched } }): ReactElement => (
                        <Input
                          id={`${WEIGHT_QUESTIONS_FIELD_NAME}.${index}.count`}
                          type="number"
                          value={input.value}
                          onChange={(event): void => input.onChange(parseToNumber(event.target.value))}
                          label="Количество заданий"
                          hintText={touched && error}
                          state={(touched && error) ? States.Error : States.Default}
                          disabled={isDisabledField(index)}
                        />
                      )}
                    />
                  </div>
                )}
              </>
            )}
          />
        ))}
      </div>
    </Paper>
  );
});
